import React, { Component } from 'react'
import Form from "react-bootstrap/Form"
import { LinkContainer } from 'react-router-bootstrap'
import LoaderButton from '../components/loaderButton'
import Card from 'react-bootstrap/Card'
import { Auth, API, Storage } from 'aws-amplify';
import { v1 as uuidv1 } from 'uuid'
import withRouter from '../components/withRouter'
import moment from 'moment'
import Checkmark from '../images/checkmark.svg'
import NodeTypes from '../components/nodeTypes'
import { FaRegWindowClose, FaEye, FaEyeSlash } from 'react-icons/fa'
import { MdAddAPhoto, MdChevronLeft, MdImageNotSupported } from 'react-icons/md'
import { BsInfoCircle } from 'react-icons/bs'
import './signup.css'

class Signup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            email: '',
            pw: '',
            cpw: '',
            code: '',
            fn: '',
            ln: '',
            zip: '',
            dob: '',
            user: false,
            image: '',
            privacy: false,
            subscribe: false,
            tou: false,
            file: null,
            curr: '1',
            imgUrl: null,
            legal: false,
            page: 'privacy',
            defaultLoc: null,
            loc: '',
            lastLoc: '',
            locIsGood: false,
            codeSent: false,
            sent: null,
            pref: null,
            options: null,
            expanded: [],
            imageSize: false,
            msg: '',
            displayMsg: false,
            showLoginLink: false,
            reset: false,
            showPW: false,
            showCPW: false,
            info: [
                { id: 0, displayInfo: true, displayMsg: false, msg: 'Set your default location and click "Check Address" to validate the address.', dir: 'left', val: '43vw' }
            ]
        }
        this.handleOptionSelect = this.handleOptionSelect.bind(this)
    }

    componentDidMount() {
        if (this.props.isAuthenticated && this.props.user) {
            this.props.onLogout()
        }
        if (this.props.myloc) {
            API.get('lt', `geo?coord=${this.props.myloc.lat},${this.props.myloc.lng}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        let zip = resp.data.results[0].formatted_address.split(', ')
                        const loc = zip[zip.length - 2].substr(3)
                        this.setState({ loc, lastLoc: loc, defaultLoc: this.props.myloc })
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        } else {
            const gl = navigator.geolocation
            gl.getCurrentPosition(position => {
                this.props.onMyLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                API.get('lt', `geo?coord=${position.coords.latitude.toFixed(7)},${position.coords.longitude.toFixed(7)}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                    .then(resp => {
                        if (resp.data && resp.data.results[0]) {
                            let zip = resp.data.results[0].formatted_address.split(', ')
                            const loc = zip[zip.length - 2].substr(3)
                            this.setState({ loc, lastLoc: loc, defaultLoc: { lat: position.coords.latitude, lng: position.coords.longitude } })
                        }
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }, err => { console.log(err) })
        }
    }

    handleClearErr() {
        this.setState({ err: null })
    }

    handleInfo(id) {
        let info = [...this.state.info]
        info.splice(id, 1, { ...this.state.info[0], displayMsg: !this.state.info[id].displayMsg })
        this.setState({ info })
    }

    handleShowPW() {
        this.setState({ showPW: !this.state.showPW })
    }

    handleShowCPW() {
        this.setState({ showCPW: !this.state.showCPW })
    }

    handleLocationCheck() {
        if (this.state.loc && this.state.loc !== this.state.lastLoc) {
            let info = [...this.state.info]
            info.splice(0, 1, { ...this.state.info[0], displayInfo: false, displayMsg: false })
            this.setState({ locIsGood: false, info })
            API.get('lt', `geo?address=${this.state.loc.replace(/#/g, '%23')}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        if (resp.data.results[0].geometry && resp.data.results[0].geometry.location) this.props.onMyLoc(resp.data.results[0].geometry.location)
                        const loc = resp.data.results[0].formatted_address || ''
                        this.setState({ loc, lastLoc: loc, locIsGood: true, defaultLoc: resp.data.results[0].geometry && resp.data.results[0].geometry.location ? resp.data.results[0].geometry.location : this.state.defaultLoc })
                    }
                })
                .catch(e => {
                    this.setState({ locIsGood: false })
                    console.log(e)
                })
        }
    }

    handleChange = event => {
        switch (event.target.id) {
            case 'pw':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\d\w!@#$%^&*()_+-=[\]{}|<>]/g, "").substring(0, 127) })
                break
            case 'cpw':
                this.setState({ [event.target.id]: event.target.value.replace(/[<>// ]/g, "") })
                break
            case 'email':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@.]/g, "").toLowerCase() })
                break
            case 'zip':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\d]/g, "").substring(0, 9) })
                break
            case 'loc':
                let info = [...this.state.info]
                info.splice(0, 1, { ...this.state.info[0], displayInfo: event.target.value.length > 0, displayMsg: false })
                this.setState({ info, [event.target.id]: event.target.value.replace(/[^a-z,A-Z0-9 ]/g, "") })
                break
            case 'dob':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\d-/\\]/g, '') })
                break
            default:
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9- ]/g, "") })
        }
    }

    handleImageChange(e) {
        const file = e.target.files[0]
        if (file.size < 5242880) {
            if (file.type.indexOf('image/') > -1) {
                let image = uuidv1() + file.type.replace('image/', '.')
                this.setState({ file, image, imgUrl: URL.createObjectURL(file) })
            }
        } else this.setState({ imageSize: true })
        this.fileInput.value = ''
    }

    async handleUploadImage() {
        if (this.state.file && this.state.image.length > 0) {
            await Storage.put(`${this.state.image}`, this.state.file, {
                progressCallback(progress) { console.log(`Uploaded: ${progress.loaded}/${progress.total}`) }
            })
        }
    }

    handleSubmit = async event => {
        event.preventDefault()
        window.scrollTo(0, 0)
        if (this.formIsValid()) {
            this.setState({ isLoading: true })
            Auth.signUp({ username: this.state.email, password: this.state.pw })
                .then(resp => {
                    this.setState({ curr: '2', sent: moment().add(5, 'minutes') })
                })
                .catch(e => {
                    console.log(e)
                    if (e.code === 'UsernameExistsException') {
                        Auth.signIn(this.state.email, this.state.pw)
                            .then(resp => {
                                API.get('lt', 'user')
                                    .then(resp => {
                                        this.props.setUser({ ...resp.data })
                                        this.props.userHasAuthenticated(true)
                                        this.handleDone()
                                    })
                                    .catch(e => {
                                        API.post('lt', 'user', {
                                            body: {
                                                email: this.state.email.toLowerCase(),
                                                firstName: this.state.fn,
                                                lastName: this.state.ln,
                                                zip: this.state.zip,
                                                dob: moment(this.state.dob).format('YYYY-MM-DD'),
                                                tou: this.state.tou,
                                                privacy: this.state.privacy,
                                                subscribe: this.state.subscribe
                                            }
                                        })
                                            .then(resp => {
                                                this.props.setUser({ ...resp.user })
                                                this.props.userHasAuthenticated(true)
                                                this.setState({ curr: '3' })
                                            })
                                            .catch(e => {
                                                this.setState({ isLoading: false, msg: e.message, displayMsg: true })
                                                console.log(e)
                                            })
                                    })
                            })
                            .catch(e => {
                                if (e.code === 'UserNotConfirmedException') {
                                    Auth.resendSignUp(this.state.email)
                                        .then(resp => {
                                            this.setState({ curr: '2' })
                                        })
                                        .catch(e => {
                                            console.log(e)
                                            this.setState({ msg: e.message, displayMsg: true })
                                        })
                                } else {
                                    this.setState({ msg: 'This account already exists. If this is your account, select Log In below. Forgot your password? Use Log In below to reset. If you were unsuccessful in completing your account registration, please select OK to reset your account and we will notify you when Sign Up is available.', displayMsg: true, showLoginLink: true, reset: true })
                                    console.log(e.message)
                                }
                            })
                    } else {
                        this.setState({ msg: e.message, displayMsg: true })
                        console.log(e.message)
                    }
                })
            this.setState({ isLoading: false })
        }
    }

    handleConfirmSubmit = async event => {
        event.preventDefault()
        if (this.codeIsValid()) {
            window.scrollTo(0, 0)
            this.setState({ isLoading: true })
            try {
                await Auth.confirmSignUp(this.state.email, this.state.code)
                await Auth.signIn(this.state.email, this.state.pw)
                //let currUser = await Auth.currentAuthenticatedUser()
                API.post('lt', 'user', {
                    body: {
                        email: this.state.email.toLowerCase(),
                        firstName: this.state.fn,
                        lastName: this.state.ln,
                        zip: this.state.zip,
                        dob: moment(this.state.dob).format('YYYY-MM-DD'),
                        tou: this.state.tou,
                        privacy: this.state.privacy,
                        subscribe: this.state.subscribe
                    }
                })
                    .then(resp => {
                        this.props.setUser({ ...resp.user })
                        this.props.userHasAuthenticated(true)
                        this.setState({ curr: '3', isLoading: false })
                    })
                    .catch(e => {
                        this.setState({ isLoading: false, msg: e.message, displayMsg: true })
                        console.log(e)
                    })
            } catch (e) {
                console.log(e.message)
                this.setState({ isLoading: false, err: e.message })
            }
        }
    }

    handleProfileSubmit = async event => {
        event.preventDefault()
        window.scrollTo(0, 0)
        this.setState({ isLoading: true })
        API.post('lt', 'user', {
            body: {
                image: this.state.image,
                defaultLoc: { coord: this.state.defaultLoc },
                defaultAddr: this.state.locIsGood ? this.state.loc : ''
            }
        })
            .then(async resp => {
                this.props.setUser({ ...resp.user })
                await this.handleUploadImage()
                API.get('lt', 'options?prop=node')
                    .then(resp => this.setState({ options: resp.data || [] }))
                    .catch(e => console.log(e))
                this.setState({ curr: '4', isLoading: false })
            })
            .catch(e => {
                console.log(e)
                this.setState({ curr: '5', isLoading: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
            })
    }

    handlePrefSubmit = event => {
        event.preventDefault()
        window.scrollTo(0, 0)
        this.setState({ isLoading: true })
        API.post('lt', 'user', {
            body: {
                preferences: this.state.pref
            }
        })
            .then(resp => {
                this.props.setUser({ ...resp.user })
                this.setState({ curr: '5', isLoading: false })
                //const { navigate } = this.props
                //navigate('/')
            })
            .catch(e => {
                this.setState({ curr: '5', isLoading: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                console.log(e)
            })
    }

    handleDone() {
        const { navigate } = this.props
        navigate('/')
    }

    handleClearPic() {
        this.setState({ file: null, image: '', imgUrl: null })
    }

    handleBack() {
        const { navigate } = this.props
        navigate('/')
    }

    handleOpenLegal(page) {
        window.scrollTo(0, 0)
        this.setState({ legal: true, page })
    }

    handleCloseLegal() {
        this.setState({ legal: false })
    }

    handleCheckChanged(e) {
        this.setState({ [e.target.value]: e.target.checked })
    }

    handleResendCode() {
        if (this.state.sent && moment().isAfter(this.state.sent)) {
            this.setState({ codeSent: false })
            Auth.resendSignUp(this.state.email)
                .then(resp => {
                    this.setState({ codeSent: true, sent: moment().add(5, 'minutes') })
                })
                .catch(e => {
                    console.log(e)
                    this.setState({ msg: e.message, displayMsg: true })
                })
        } else {
            this.setState({ msg: 'Please allow at least 5 minutes before requesting a new Reset Code.', displayMsg: true })
        }
    }

    handleOptionSelect(node, cat) {
        let pref = { ...this.state.pref }
        let pidx = Object.keys(pref).findIndex(p => p === node)
        if (pidx > -1) {
            let cidx = pref[node].indexOf(cat)
            if (cidx > -1) {
                pref[node].splice(cidx, 1)
            } else pref[node].push(cat)
        } else {
            pref[node] = [cat]
        }
        this.setState({ pref })
    }

    handleMsgOK() {
        if (this.state.reset) {
            const body = {
                type: 'contactus',
                category: 'Unconfirmed Account',
                subject: `Delete Unconfirmed Account - ${this.state.email}`,
                description: `Account ${this.state.email} for ${this.state.fn} ${this.state.ln} needs to be deleted from the User Pool so user may re-attempt registration.`
            }
            const headers = { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` }
            API.post('lt', 'contactus', { body, headers })
                .then(resp => {
                    const { navigate } = this.props
                    navigate('/')
                })
                .catch(e => {
                    console.log(e)
                    this.setState({ msg: e.message, displayMsg: true })
                })
        } else this.setState({ msg: '', displayMsg: false })
    }

    handleLogin() {
        const { navigate } = this.props
        navigate('/login')
    }

    codeIsValid() {
        return this.state.code.length > 0
    }

    formIsValid() {
        let testpw = /[\d\W!@#$%^&*()_+-=[\]{}|<>]/
        return (
            this.state.fn.length > 0 &&
            this.state.email.length > 5 &&
            this.state.ln.length > 0 &&
            this.state.dob.length > 0 && moment(this.state.dob).isValid() && moment(this.state.dob).isBefore(moment().subtract(13, 'years')) &&
            this.state.zip.length > 0 &&
            this.state.pw.length > 7 && testpw.test(this.state.pw) &&
            this.state.cpw === this.state.pw &&
            this.state.tou &&
            this.state.privacy
        )
    }

    prefIsSelected(node, cat) {
        if (this.state.pref) {
            let pidx = Object.keys(this.state.pref).findIndex(p => p === node)
            if (pidx > -1) {
                let cidx = this.state.pref[node].indexOf(cat)
                return cidx > -1
            } else return false
        } else return false
    }

    getValidation(field) {
        switch (field) {
            case 'email':
                return this.state.email.length > 2 && this.state.email.indexOf('@') > 0 && this.state.email.indexOf('.') > 1
            case 'pw':
                return this.state.pw.length > 7 && /[\d\W!@#$%^&*()_+-=[\]{}|<>]/.test(this.state.pw)
            case 'cpw':
                return this.state.cpw.length > 7 && this.state.cpw === this.state.pw
            case 'fn':
                return this.state.fn.length > 0
            case 'ln':
                return this.state.ln.length > 0
            case 'dob':
                return this.state.dob.length === 10 && moment(this.state.dob).isValid() && moment(this.state.dob).isBefore(moment().subtract(13, 'years'))
            case 'zip':
                return (/^\d{5,10}$/).test(this.state.zip)
            case 'code':
                return this.state.code.length > 3 && /\d+/.test(this.state.code)
            default:
                return false
        }
    }

    pwIsStrong(part) {
        switch (part) {
            case 'length':
                return this.state.pw.length > 7
            case 'upper':
                const upper = /[A-Z]/
                return upper.test(this.state.pw)
            case 'number':
                const num = /\d/
                return num.test(this.state.pw)
            case 'special':
                const spec = /[-=!@#$%&*()_{}|<>\][+^]/
                return spec.test(this.state.pw)
            default: return false
        }
    }

    getInfo(id) {
        return <div className='Info-Container' style={{ [this.state.info[id].dir]: this.state.info[0].val }}>
            <div className='Info-Row Info-Text'>{this.state.info[id].msg}</div>
            <div className="Info-Flex-Wrap">
                <div className='Info-Button Info-Button-Save' onClick={() => this.handleInfo(id)}>Got It!</div>
            </div>
        </div>
    }

    getDone() {
        return <div className="Signup">
            <div className='Signup-Heading-2'>Welcome to Gigloca, where fans can immerse themselves in a world of entertainment!</div>
            <div className='Signup-List-2'>
                We're rapidly expanding to bring excitement to every neighborhood, offering a multitude of features for your enjoyment:
                <ul>
                    <li>Discover What's Happening Now:<br />Easily stay in the loop about current events in your vicinity.</li>
                    <li>Effortlessly Plan Your Next Adventure:<br />Find the perfect spots for fun and plan your outings with ease.</li>
                    <li>Uncover Talent and Creativity:<br />Explore a diverse array of artists, musicians, and contributors who make each event special.</li>
                    <li>Share and Recommend:<br />Spread the joy by sharing and recommending events with your friends.</li>
                    <li>Provide Valuable Feedback:<br />Rate and give feedback on venues, contributors, and events to enhance the Gigloca experience.</li>
                    <li>Stay Organized:<br />Keep track of events that pique your interests, ensuring you never miss out on the excitement.</li>
                    <li>And Much More!<br />Dive into a world of possibilities and explore all that Gigloca offers.</li>
                </ul>
            </div>
            <div className='Signup-Heading-2'>Join the Fun:</div>
            <div className='Signup-List-2'>Become an integral part of the excitement by contributing your talents to the Gigloca App!
                <ul>
                    <li>Raise Awareness:<br />Promote your events and performances to a wider audience.</li>
                    <li>Expand Your Reach:<br />Attract both new and familiar faces to your events.</li>
                    <li>Effortlessly Share Your Schedule:<br />Advertise your calendar of upcoming events with ease.</li>
                    <li>Connect with Contributors:<br />Allow your venues to attract contributors for collaborative events.</li>
                    <li>Find Open Gigs:<br />Quickly locate and apply for open gigs tailored to your talents.</li>
                    <li>Who should sign up:
                        <ul>
                            <li>Venues:<br />Open your doors to a wider audience and attract contributors for your events.</li>
                            <li>Live Music Performers:<br />Showcase your talent and connect with a broad and diverse audience.</li>
                            <li>Trivia & Karaoke Hosts:<br />Bring the fun to different venues and engage with enthusiastic participants.</li>
                            <li>Security and Equipment Suppliers:<br />Connect with event organizers in need of your services.</li>
                            <li>Food Trucks and Catering:<br />Provide delicious treats to event-goers and make your mark on the local scene.</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className='Signup-Heading-2'>Join Gigloca and be a part of the thriving entertainment community!</div>
            <div className='Signup-List-2'>
                <ul>
                    <li><LinkContainer to='/my/venues'><div className='Signup-Link'>Add your Venue Profile Here</div></LinkContainer></li>
                    <li><LinkContainer to='/my/contributors'><div className='Signup-Link'>Add your Contributor Profile Here</div></LinkContainer></li>
                </ul>
            </div>
            <form onSubmit={() => this.handleDone()}>
                <LoaderButton
                    size="lg"
                    disabled={false}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Let's Get Started"
                    loadingText="Wrapping Up..."
                    className='Signup-Button'
                />
            </form>
        </div>
    }

    getOptions() {
        return this.state.options ? <div className='Signup-Options-Container'>
            <NodeTypes options={this.state.options} onOptionsSelected={this.handleOptionSelect} pref={this.state.pref} />
        </div> : null
    }

    getMessage() {
        return <div className='Signup-Confirm'>
            <div className='Signup-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Signup-Row Node-Flex-Wrap">
                <div className='Signup-Confirm-Button' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
            {this.state.showLoginLink && <div className='Signup-Row'>
                <div className='Signup-Link Info-Link' onClick={() => this.handleLogin()}>Log In</div>
            </div>}
        </div>
    }

    getdoc() {
        switch (this.state.page) {
            case 'privacy':
                return <div className='Signup-Legal'>
                    <div className='Signup-Legal-Close' onClick={() => this.handleCloseLegal()}><FaRegWindowClose /></div>
                    <Card>
                        <Card.Header><h2>GigLoca Privacy Policy</h2></Card.Header>
                        <Card.Body>
                            <h1>Privacy Policy</h1>
                            <p>
                                Effective 3/21/2024, Gigloca values your privacy and is committed to protecting your personal information.  This Privacy Policy describes how Gigloca and Entertainment Collaborations, LLC. ("Gigloca," "we," "us," or "our") collects, utilizes and shares information about you when you use Gigloca (the "Website", the "Native Application", the "Mobile Application", the "Application", the "App" or the "Service").
                            </p>
                            <p><b>
                                1. Information We Collect
                            </b></p>
                            <p>
                                a.  Information You Provide : When you use our Service, you may provide us with certain personal information such as your name, email address and location.<br />
                                b.  Information We Collect Automatically : We may also automatically collect certain information about your use of the Services, such as your location or search activity, for the purpose of tailoring your experience to events and venues within your local entertainment area.
                            </p>
                            <p><b>
                                2. How We Use Your Information
                            </b></p>
                            <p>
                                We may use the information we collect for various purposes, including:
                            </p>
                            <p>
                                a. To provide, secure and maintain our Services.<br />
                                b. To improve our Services and develop new features that better support our users.<br />
                                c. To communicate with you, including responding to your inquiries and providing customer support.<br />
                                d. To analyze usage trends and preferences so that we can support local partners that shape the event options in your area.<br />
                                e. To detect, prevent, and address technical issues and security vulnerabilities.
                            </p>
                            <p><b>
                                3. Information Sharing
                            </b></p>
                            <p>
                                While Gigloca does not intend to share your personal information for marketing, we may share your information with third parties in the following circumstances:
                            </p><p>
                                a. With your consent to use our services.<br />
                                b. With service providers who help us perform our Services.<br />
                                c. To comply with legal obligations or to protect our legal rights.<br />
                                d. In connection with a merger, acquisition, or sale of assets.
                            </p>
                            <p><b>
                                4. Cookies and Similar Technologies
                            </b></p>
                            <p>
                                We do <u>not</u> use cookies or similar technologies to collect information about your use of the Services; however, if you disable cookies, it may impact your experience because it limits our ability to authenticate you and protect your privacy.
                            </p>
                            <p><b>
                                5. Data Security
                            </b></p>
                            <p>
                                We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, plese note that no method of transmission over the Internet or electronic storage is 100% secure.
                            </p>
                            <p><b>
                                6. Children's Privacy
                            </b></p>
                            <p>
                                Our Service is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you believe we have collected personal information from a child under 13, please <a href="https://gigloca.com/contactus">contact us</a> immediately.
                            </p>
                            <p><b>
                                7. Your Choices
                            </b></p>
                            <p>
                                You have certain choices regarding the information we collect and how it is used. You can opt out of receiving promotional emails from us by setting your preferences in the application at <a href="https://gigloca.com/pref">Preferences (https://gigloca.com/pref)</a>.
                            </p>
                            <p><b>
                                8. Changes to this Privacy Policy
                            </b></p>
                            <p>
                                We may update this Privacy Policy from time to time as a result of evolving regulations and environmental circumstances. We will notify you of any changes by posting the new Privacy Policy at <a href="https://gigloca.com/legal?page=privacy">Privacy (https://gigloca.com/legal?page=privacy)</a>.
                            </p>
                            <p><b>
                                9. Contact Us
                            </b></p>
                            <p>
                                If you have any questions about this Privacy Policy, please contact us at <a href="https://gigloca.com/contactus">https://gigloca.com/contactus</a>.
                            </p>
                            <p>
                                By using Gigloca, you consent to the terms of this Privacy Policy.
                            </p><p>
                                Thank you for using Gigloca!
                            </p>
                        </Card.Body>
                    </Card></div >
            case 'tou':
                return <div className='Signup-Legal'>
                    <div className='Signup-Legal-Close' onClick={() => this.handleCloseLegal()}><FaRegWindowClose /></div>
                    <Card>
                        <Card.Header><h2>GigLoca Terms of Use</h2></Card.Header>
                        <Card.Body>
                            <h1>Terms of Use</h1>
                            <p>Effective 3/21/2024, Gigloca is committed to maintaining appropriate use of our services.  This policy outlines how users are permitted to use our services.</p>
                            <p>
                                Welcome to Gigloca and <a href="https://gigloca.com">Gigloca.com</a>! These Terms of Use ("Terms") govern your use of Gigloca (the "Website", the "Native Application", the "Mobile Application", the "Application", the "App" or the "Service") provided by Entertainment Collaborations, LLC. ("Gigloca," "we," "us," or "our"). By accessing or using our Website, you agree to comply with these Terms. If you do not agree with these Terms, you may not access or use the Service.
                            </p>
                            <p><b>
                                1. Acceptance of Terms
                            </b></p>
                            <p>
                                By accessing or using Gigloca, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Website or Service.
                            </p>
                            <p><b>
                                2. Use of the Service
                            </b></p>
                            <p>
                                You may use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:
                            </p>
                            <p>
                                a. In any way that violates any applicable law or regulation.<br />
                                b. To engage in any activity that infringes on the rights of others.<br />
                                c. To transmit any material that is defamatory, obscene, or otherwise objectionable.<br />
                            </p>
                            <p><b>
                                3. Privacy Policy
                            </b></p>
                            <p>
                                Your privacy is important to us. Our <a href="https://gigloca.com/legal?page=privacy">Privacy Policy</a> explains how we collect, use, and disclose information about you. By using the Service, you consent to our Privacy Policy.
                            </p>
                            <p><b>
                                4. User Accounts
                            </b></p>
                            <p>
                                To access certain features of the Service, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur within your account.
                            </p><p>
                                Gigloca reserves the right to terminate or block any account for any reason and without notice, including but not limited to violations of these Terms or suspected fraudulent, abusive, or illegal activity.
                            </p>
                            <p><b>
                                5. Intellectual Property
                            </b></p>
                            <p>
                                All content and materials available on Gigloca, including but not limited to text, graphics, logos, images, and software, are the property of Gigloca and Entertainment Collaborations, LLC., and are protected by copyright, trademark, and other intellectual property laws.
                            </p>
                            <p><b>
                                6. Limitation of Liability
                            </b></p>
                            <p>
                                To the fullest extent permitted by law, Gigloca and Entertainment Collaborations, LLC. shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of the Service.
                            </p>
                            <p><b>
                                7. Modifications to Terms
                            </b></p>
                            <p>
                                Gigloca reserves the right to modify or update these Terms at any time without prior notice. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.  Terms of use may be accessed at <a href="https://gigloca.com/legal?page=tou">https://gigloca.com/legal?page=tou</a>.
                            </p>
                            <p><b>
                                8. Governing Law
                            </b></p>
                            <p>
                                These Terms shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law principles.
                            </p>
                            <p><b>
                                9. Contact Us
                            </b></p>
                            <p>
                                If you have any questions about these Terms, please contact us at <a href="https://gigloca.com/contactus">https://gigloca.com/contactus</a>.
                            </p><p>
                                By using Gigloca, you agree to these Terms of Use.
                            </p><p>
                                Thank you for using Gigloca!
                            </p>
                        </Card.Body>
                    </Card> </div >
            default:
                return null
        }
    }

    getUserForm() {
        return (
            <div className="Signup">
                {this.state.legal && this.getdoc()}
                <div className='Signup-Heading Signup-Heading-Container Signup-Group'><div className='Signup-Heading-Item Signup-Heading-Icon' onClick={() => this.handleBack()}><MdChevronLeft /></div><div className='Signup-Heading-Item'>Sign Up</div></div>
                <form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="fn" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>First Name *</Form.Label>
                        <Form.Control
                            placeholder="First Name"
                            type="text"
                            value={this.state.fn}
                            onChange={this.handleChange}
                            isValid={this.getValidation('fn')}
                            isInvalid={!this.getValidation('fn')}
                        />
                    </Form.Group>
                    <Form.Group controlId="ln" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Last Name *</Form.Label>
                        <Form.Control
                            placeholder="Last Name"
                            type="text"
                            value={this.state.ln}
                            onChange={this.handleChange}
                            isValid={this.getValidation('ln')}
                            isInvalid={!this.getValidation('ln')}
                        />
                    </Form.Group>
                    <Form.Group controlId="dob" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Date of Birth *</Form.Label>
                        <Form.Control
                            type="date"
                            value={this.state.dob}
                            onChange={this.handleChange}
                            isValid={this.getValidation('dob')}
                            isInvalid={!this.getValidation('dob')}
                        />
                        <Form.Text className="Signup-Hint">Users must be over the age of 13 years old.</Form.Text>
                    </Form.Group>
                    <Form.Group controlId="zip" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Zip Code *</Form.Label>
                        <Form.Control
                            placeholder="Zip Code (#####)"
                            type="text"
                            value={this.state.zip}
                            onChange={this.handleChange}
                            isValid={this.getValidation('zip')}
                            isInvalid={!this.getValidation('zip')}
                        />
                    </Form.Group>
                    <Form.Group controlId="email" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Email *</Form.Label>
                        <Form.Control
                            placeholder="Email (me@email.com)"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            isValid={this.getValidation('email')}
                            isInvalid={!this.getValidation('email')}
                        />
                    </Form.Group>
                    <Form.Group controlId="pw" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Password *</Form.Label>
                        <div className='Signup-Heading-Container'><Form.Control
                            placeholder="Password"
                            value={this.state.pw}
                            onChange={this.handleChange}
                            type={this.state.showPW ? "text" : "password"}
                            isValid={this.getValidation('pw')}
                            isInvalid={!this.getValidation('pw')}
                        /><div className='Signup-Icon' onClick={() => this.handleShowPW()}>{this.state.showPW ? <FaEye /> : <FaEyeSlash />}</div></div>
                        <Form.Text className="Signup-Hint">Password must be at least <span className={this.pwIsStrong('length') ? 'Signup-Strong' : 'Signup-Weak'}>8 characters</span>, <span className={this.pwIsStrong('number') ? 'Signup-Strong' : 'Signup-Weak'}>1 number</span>, <span className={this.pwIsStrong('special') ? 'Signup-Strong' : 'Signup-Weak'}>1 special character</span> and <span className={this.pwIsStrong('upper') ? 'Signup-Strong' : 'Signup-Weak'}>1 uppercase letter</span></Form.Text>
                    </Form.Group>
                    <Form.Group controlId="cpw" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Re-enter Password *</Form.Label>
                        <div className='Signup-Heading-Container'><Form.Control
                            placeholder="Re-enter Password"
                            value={this.state.cpw}
                            onChange={this.handleChange}
                            type={this.state.showCPW ? "text" : "password"}
                            isValid={this.getValidation('cpw')}
                            isInvalid={!this.getValidation('cpw')}
                        /><div className='Signup-Icon' onClick={() => this.handleShowCPW()}>{this.state.showCPW ? <FaEye /> : <FaEyeSlash />}</div></div>
                    </Form.Group>
                    <div className='Signup-Checkbox'>
                        <input value="privacy" checked={this.state.privacy} type="checkbox" onChange={(event) => this.handleCheckChanged(event)} />
                        <span className='Signup-Checkbox-Text'>I accept the GigLoca <span className='Signup-Link' onClick={() => this.handleOpenLegal('privacy')}>Privacy Policy</span></span>
                    </div>
                    <div className='Signup-Checkbox'>
                        <input value="tou" type="checkbox" checked={this.state.tou} onChange={(event) => this.handleCheckChanged(event)} />
                        <span className='Signup-Checkbox-Text'>I accept the GigLoca <span className='Signup-Link' onClick={() => this.handleOpenLegal('tou')}>Terms of Use</span></span>
                    </div>
                    <div className='Signup-Checkbox'>
                        <input value="subscribe" type="checkbox" checked={this.state.subscribe} onChange={(event) => this.handleCheckChanged(event)} />
                        <span className='Signup-Checkbox-Text'>I would like to receive updates and the GigLoca Newsletter</span>
                    </div>
                    <LoaderButton
                        size="lg"
                        disabled={!this.formIsValid()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Signup"
                        loadingText="Signing up…"
                        className='Signup-Button'
                    />
                </form>
            </div>
        )
    }

    getConfirmForm() {
        return (
            <div className="Signup">
                <div className='Signup-Heading Signup-Group'>Verification Code</div>
                <div className='Signup-Heading Signup-Hint Signup-Group-2'>{`Please check ${this.state.email} for your verification code`}</div>
                <form onSubmit={this.handleConfirmSubmit}>
                    <Form.Group controlId="code" className="form-group-lg Signup-Group-3">
                        <Form.Label className='Login-Input'>Code</Form.Label>
                        <Form.Control
                            autoFocus
                            type="tel"
                            placeholder="Code from Email"
                            value={this.state.code}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <div className='Signup-Heading Signup-Hint Signup-Group-2'>{`${this.state.codeSent ? `Code resent to ${this.state.email}` : "Didn't receive your code?"}`} <span className='Signup-Link' onClick={() => this.handleResendCode()}>Resend Code</span>.</div>
                    <LoaderButton
                        size="lg"
                        disabled={!this.codeIsValid()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Verify"
                        loadingText="Verifying…"
                        className='Signup-Button'
                    />
                </form>
            </div>
        )
    }

    getProfileForm() {
        return <div className="Signup">
            <div className='Signup-Heading Signup-Group Signup-Welcome'>{`Welcome ${this.state.fn}!`}</div>
            <div className='Signup-Photo-Container'>
                {
                    this.state.imgUrl ? <img src={this.state.imgUrl} alt="Profile" className='Signup-Profile' onClick={() => this.handleClearPic()} /> :
                        <label className="Signup-Profile Signup-Profile-Icon">
                            <input type="file" onChange={event => this.handleImageChange(event)} ref={ref => this.fileInput = ref} />
                            {this.state.imageSize ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}
                        </label>
                }
            </div>
            <div className='Signup-Profile-Form'>
                <label className='Signup-Profile-Text'>
                    <input type="file" onChange={event => this.handleImageChange(event)} ref={ref => this.fileInput = ref} />
                    {`${this.state.imgUrl ? 'Want to Change it?' : 'Edit Profile Picture'}`}
                </label>
                <div className='Signup-Default-Container'>
                    <div className='Signup-Heading Signup-Group Signup-Default-Text'>Set Your Default Location</div>
                </div>
                <div className='Signup-Group-3'>
                    <div className='Signup-Heading-Container'><div className='Signup-Heading-Item'>
                        <Form.Group controlId="loc" className="form-group-lg">
                            <Form.Label className='Login-Input Signup-Label-Left'>Location</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Home Base"
                                value={this.state.loc}
                                onChange={this.handleChange}
                            />
                        </Form.Group></div>
                        {this.state.locIsGood && <div className='Signup-Heading-Item'><img src={Checkmark} alt="Verified" className='Signup-Heading-Checkmark' /></div>}
                    </div>
                </div>
                <div className='Signup-Flex'>
                    <div className='Signup-Hint Signup-Link' onClick={() => this.handleLocationCheck()}>Check Address</div>
                    {this.state.info[0].displayInfo && <div className='Info-Icon' onClick={() => this.handleInfo(0)}><BsInfoCircle /></div>}
                    {this.state.info[0].displayMsg && this.getInfo(0)}
                </div>
                <form onSubmit={this.handleProfileSubmit}>
                    <LoaderButton
                        size="lg"
                        disabled={false}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text={this.state.image || this.state.locIsGood ? "Next" : "Skip"}
                        loadingText="Updating…"
                        className='Signup-Button'
                    /></form>
            </div>
        </div>
    }

    getPrefForm() {
        return (
            <div className="Signup">
                <div className='Signup-Heading Signup-Group'>Select Favorites:</div>
                <div>{this.getOptions()}</div>
                <div className='Signup-Profile-Form'>
                    <form onSubmit={this.handlePrefSubmit}>
                        <LoaderButton
                            size="lg"
                            disabled={false}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text="Let's Gigit!"
                            loadingText="Updating…"
                            className='Signup-Button'
                        /></form>
                </div>
            </div>
        )
    }

    render() {
        //console.log(this.state, this.props)
        return (
            <div className='Signup-Container'>
                {this.state.curr === '5' ? this.getDone() : (this.state.curr === '4' ? this.getPrefForm() : (this.state.curr === '3' ? this.getProfileForm() : (this.state.curr === '2' ? this.getConfirmForm() : this.getUserForm())))}
                {
                    this.state.displayMsg && this.getMessage()
                }
            </div>
        )
    }
}

export default withRouter(Signup)