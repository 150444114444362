import { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import moment from 'moment'
import { FaStar, FaHeart, FaRegHeart, FaMapMarkedAlt, FaRegGrinStars } from 'react-icons/fa'
import { FaHeartCircleCheck } from 'react-icons/fa6'
import { BsCalendar2, BsCalendar2Check, BsCalendarFill, BsClockHistory, BsDash } from 'react-icons/bs'
import { BiBookmarkHeart, BiBookmarkPlus } from 'react-icons/bi'
import { RiPinDistanceFill } from 'react-icons/ri'
import { LuCopy, LuCopyCheck } from 'react-icons/lu'
import { IoEnterOutline } from 'react-icons/io5'
import { getAddress, temp } from '../helpers'
import './card.css'

class Card extends Component {
    constructor(props) {
        super(props)

        this.state = {
            expand: false,
            expandText: false,
            copy: false
        }
    }

    getCImages() {
        let cImages = []
        if (this.props.data.events) {
            this.props.data.events.forEach(e => {
                if (e.nodes) {
                    e.nodes.forEach(n => {
                        if (n.imageArray) {
                            let images = n.imageArray.map(i => { return { name: n.name, img: i, date: moment(e.startTime).format('MMM DD') } })
                            cImages = [...cImages, ...images]
                        }
                    })
                }
            })
        }
        return cImages
    }

    handleSelected() {
        if (this.props.onEdit) {
            this.props.onEdit(this.props.type === 'event' ? this.props.data.events[0].id : this.props.data.id, this.props.type === 'event' ? 'event' : this.props.data.nodetype)
        } else this.props.onSelected(this.props.type === 'event' ? this.props.data.events[0].id : this.props.data.id, this.props.type)
    }

    handleFollow() {
        this.props.onFollow(this.props.data.id)
    }

    handleInterest() {
        this.props.onInterest(this.props.data.events[0].id, this.props.data.events[0].venue)
    }

    handleAttending() {
        this.props.onAttending(this.props.data.events[0].id, this.props.data.events[0].venue)
    }

    handleViewMore() {
        if (this.state.expand) {
            this.setState({ expand: false, expandText: false })
        } else {
            this.setState({ expand: true })
            setTimeout(() => this.setState({ expandText: true }), 250)
        }
    }

    handleCopy(addr) {
        this.setState({ copy: true })
        navigator.clipboard.writeText(addr)
    }

    getDetails() {
        let text = this.props.type === 'event' ? (this.props.data.events[0].details || '') : (this.props.data.description || '')
        if (text.length > 117) {
            return this.state.expandText ? text : `${text.substring(0, 114)}...`
        } else return text
    }

    getMyStars(irated) {
        let stars = []
        for (let i = 0; i < irated; i++) {
            stars.push(i + 1)
        }
        return stars.map(s => {
            return <div key={`star-${s}`} className='MiniWide-Icon' style={{ color: 'yellow' }}><FaStar /></div>
        })
    }

    render() {
        //console.log(this.props)
        return <div className='Card-Container'>
            <div className={`Card-${this.props.type}`}>
                <div className='Card-Header'>
                    <div className='Card-Row'>
                        <div className='Card-TopLeft'>
                            {
                                this.props.type !== 'event' && this.props.data.followers && this.props.data.followers > 0 ? <div style={{ marginLeft: '10px', marginTop: '10px' }} className='MiniWide-Flex'>
                                    <div className='Card-Heart'>
                                        <FaHeart />
                                        <div className='Card-Heart-Text'>{this.props.data.followers > 1000 ? `${(this.props.data.followers / 1000).toFixed(1)}k` : this.props.data.followers}</div>
                                    </div>
                                </div> : null
                            }
                            {
                                this.props.type === 'event' && ((this.props.data.events[0].interested || this.props.data.events[0].attending) && (((this.props.data.events[0].interested || 0) + (this.props.data.events[0].attending || 0)) > 0)) ? <div style={{ marginLeft: '10px', marginTop: '10px' }} className='MiniWide-Flex'>
                                    <div className='Card-Heart'>
                                        <FaHeart />
                                        <div className='Card-Heart-Text'>{((this.props.data.events[0].interested || 0) + (this.props.data.events[0].attending || 0)) > 1000 ? `${(((this.props.data.events[0].interested || 0) + (this.props.data.events[0].attending || 0)) / 1000).toFixed(1)}k` : ((this.props.data.events[0].interested || 0) + (this.props.data.events[0].attending || 0))}</div>
                                    </div>
                                </div> : null
                            }
                        </div>
                        {
                            this.props.type === 'event' ?
                                <div className='Card-Title' onClick={() => this.handleSelected()}>
                                    <div>{this.props.data.events && this.props.data.events[0] ? this.props.data.events[0].title : 'Event'}<span style={{ marginLeft: '5px' }}><IoEnterOutline /></span></div>
                                    <div style={{ fontSize: '11pt' }}>{this.props.data.name}</div>
                                </div> :
                                <div className='Card-Title' onClick={() => this.handleSelected()}>{this.props.data.name}<span style={{ marginLeft: '5px' }}><IoEnterOutline /></span></div>
                        }
                        <div>
                            {this.props.type !== 'event' && this.props.data.stars && this.props.data.stars > 0 ? <div style={{ marginLeft: '10px' }} className='Card-Flex'>
                                <div className='Card-Stars'><FaStar /></div>
                                <div className='Card-Stars-Text'>{this.props.data.stars.toFixed(1)}</div>
                            </div> : null}
                            {this.props.type === 'event' && this.props.data.events[0].stars && this.props.data.events[0].stars > 0 ? <div style={{ marginLeft: '10px' }} className='Card-Flex'>
                                <div className='Card-Stars'><FaStar /></div>
                                <div className='Card-Stars-Text'>{this.props.data.events[0].stars.toFixed(1)}</div>
                            </div> : null}
                        </div>
                    </div>
                </div>
                <div className='Card-Body'>
                    <div className='Card-Row'>
                        <div>
                            <div className='Card-New-Section-Container'>
                                <div className='Card-New-Section-Title'>Description</div>
                                <div className='Card-New-Section-Divider'></div>
                            </div>
                            <div className='Card-Row'>
                                <div className={`Card-Textbox ${this.state.expand ? 'Card-Textbox-Max' : 'Card-Textbox-Min'}`}>
                                    {this.getDetails()}
                                </div>
                                {(this.props.type === 'event' ? (this.props.data.events[0].details || '') : (this.props.data.description || '')).length > 117 && <div className='Card-New-Section-Button' onClick={() => this.handleViewMore()}>{this.state.expand ? 'Less' : 'More'}</div>}
                            </div>
                        </div>
                        <div style={{marginTop: '-4px'}}>
                            {
                                <Carousel className='Card-CI' controls={false} touch={false} indicators={false} interval={1500} fade>
                                    {
                                        this.getCImages().map((ci, imgidx) => {
                                            return <Carousel.Item key={`ci-${imgidx}`}>
                                                <div className='Card-CI-Text'>{ci.date}</div>
                                                <img src={`https://localtalent-upload.s3.amazonaws.com/public/${ci.img}`} className='Card-CI' alt={ci.name} />
                                                <div className='Card-CI-Text'>{ci.name.substring(0, 22)}</div>
                                            </Carousel.Item>
                                        })
                                    }
                                </Carousel>
                            }
                        </div>
                    </div>
                </div>
                <div className='Card-Footer'>
                    <div className='Card-Row'>
                        {
                            this.props.data.address && <div className='Card-Footer-Item' style={{ cursor: 'pointer', width: '285px', maxHeight: '45px', overflowY: 'auto' }} onClick={() => this.handleCopy(getAddress(this.props.data.address))}>
                                <div className='Card-Footer-Icon'><FaMapMarkedAlt /></div>
                                <div className='Card-Footer-Text'>{getAddress(this.props.data.address)}</div>
                                <div className='Card-Footer-Icon'>{this.state.copy ? <LuCopyCheck /> : <LuCopy />}</div>
                            </div>
                        }
                        {this.props.data.distance && <div className={`Card-Footer-Item${this.props.near ? ' Card-Nearby' : ''}`}>
                            <div className='Card-Footer-Icon'><RiPinDistanceFill /></div>
                            <div className='Card-Footer-Text'>{`${this.props.data.distance.toFixed(1)} mi`}</div>
                        </div>}
                    </div>
                    {this.props.type === 'event' ? <div className='Card-Row'>
                        <div className='Card-Footer-Item'>
                            <div className='Card-Footer-Icon'><BsCalendarFill /></div>
                            <div className='Card-Footer-Text'>{this.props.data.events && this.props.data.events[0] ? moment(this.props.data.events[0].startTime).format('MMM DD') : ''}</div>
                        </div>
                        <div className='Card-Footer-Item'>
                            <div className='Card-Footer-Icon'><BsClockHistory /></div>
                            <div className='Card-Footer-Text'>{this.props.data.events && this.props.data.events[0] ? moment(this.props.data.events[0].startTime).format('h:mm A') : ''}</div>
                        </div>
                        <div className='Card-Footer-Item'>
                            <div className='Card-Footer-Icon'><BsDash /></div>
                        </div>
                        <div className='Card-Footer-Item'>
                            <div className='Card-Footer-Icon'><BsCalendarFill /></div>
                            <div className='Card-Footer-Text'>{this.props.data.events && this.props.data.events[0] ? moment(this.props.data.events[0].stopTime).format('MMM DD') : ''}</div>
                        </div>
                        <div className='Card-Footer-Item'>
                            <div className='Card-Footer-Icon'><BsClockHistory /></div>
                            <div className='Card-Footer-Text'>{this.props.data.events && this.props.data.events[0] ? moment(this.props.data.events[0].stopTime).format('h:mm A') : ''}</div>
                        </div>
                    </div> : <div className='Card-Row'>
                        <div className='Card-Footer-Item'></div>
                    </div>}
                    <div className='Card-Row'>
                        {this.props.type === 'event' && this.props.data.events[0] && this.props.data.events[0].weather && this.props.data.events[0].weather[0] ? <div className='Card-Footer-Item'>
                            <img src={`https://openweathermap.org/img/wn/${this.props.data.events[0].weather[0].weather[0].icon}@2x.png`} className='Card-Footer-Weather' alt={this.props.data.events[0].weather[0].weather[0].main} />
                            <div className='Card-Footer-Text'>{this.props.data.events[0].weather[0].summary} {`(${temp(this.props.data.events[0].weather[0].temp.min)}F - ${temp(this.props.data.events[0].weather[0].temp.max)}F)`}</div>
                        </div> : <div style={{ height: '33px' }} className='Card-Footer-Item'>
                        {
                                this.props.type !== 'event' && this.props.data.irated ? <div style={{ marginTop: '-8px', marginLeft: '5px' }} className='MiniWide-Flex'><div className='MiniWide-Icon' style={{ color: '#fff' }}><FaRegGrinStars /></div>{this.getMyStars(this.props.data.irated)}</div> : null
                            }
                            {
                                this.props.type === 'event' && this.props.data.events && this.props.data.events[0].irated ? <div style={{ marginTop: '-8px', marginLeft: '5px' }} className='MiniWide-Flex'><div className='MiniWide-Icon' style={{ color: '#fff' }}><FaRegGrinStars /></div>{this.getMyStars(this.props.data.events[0].irated)}</div> : null
                            }
                        </div>}
                    </div>
                    {this.props.type === 'event' && this.props.onAttending && this.props.onInterest ? <div className='Card-Row'>

                        <div className='Card-Item Card-Gigin-Icon' style={{ cursor: 'pointer' }} onClick={() => this.handleInterest()}>{this.props.data.events[0].iaminterested ? <BiBookmarkHeart /> : <span style={{ color: '#b3b3b3' }}><BiBookmarkPlus /></span>}</div>
                        <div className='Card-Item Card-Gigin-Icon' style={{ cursor: 'pointer' }} onClick={() => this.handleAttending()}>{this.props.data.events[0] && this.props.data.events[0].iamattending ? <BsCalendar2Check /> : <span style={{ color: '#b3b3b3' }}><BsCalendar2 /></span>}</div>
                    </div> : this.props.onFollow && <div className='Card-Row'>
                        <div className={`Card-Gigin${this.props.data.iamfollowing ? ' Card-Gigin-In' : ''}`} onClick={() => this.handleFollow()}>{this.props.data.iamfollowing ? 'Unfollow' : 'Follow'}</div>
                        <div className='Card-Item Card-Gigin-Icon'>{this.props.data.iamfollowing ? <FaHeartCircleCheck /> : <span style={{ color: '#b3b3b3' }}><FaRegHeart /></span>}</div>
                    </div>}
                </div>
                <div className='Card-BG'>
                    {
                        this.props.type === 'event' && this.props.data.events[0] && this.props.data.events[0].imageArray ? <Carousel style={{ maxWidth: '350px', margin: '0 auto' }} controls={false} touch={false} indicators={false} fade>
                            {
                                this.props.data.events[0].imageArray.map((img, imgidx) => {
                                    return <Carousel.Item key={`ci-${imgidx}`}>
                                        <div style={{}}>
                                            <img src={`https://localtalent-upload.s3.amazonaws.com/public/${img}`} className='Card-BG-CI' alt='' />
                                        </div>
                                    </Carousel.Item>
                                })
                            }
                        </Carousel> :
                            (this.props.type !== 'event' && this.props.data && this.props.data.imageArray && <Carousel style={{ maxWidth: '350px', margin: '0 auto' }} controls={false} touch={false} indicators={false} fade>
                                {
                                    this.props.data.imageArray.map((img, imgidx) => {
                                        return <Carousel.Item key={`ci-${imgidx}`}>
                                            <div style={{}}>
                                                <img src={`https://localtalent-upload.s3.amazonaws.com/public/${img}`} className='Card-BG-CI' alt='' />
                                            </div>
                                        </Carousel.Item>
                                    })
                                }
                            </Carousel>)
                    }
                </div>
            </div>
        </div>
    }
}

export default Card