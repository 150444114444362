import React from 'react'
import { RedditShareButton, RedditIcon } from 'react-share'

const ShareReddit = (props) => {
    //console.log(props)
    return <div>
        <RedditShareButton url={props.url} title={props.title}>
            <RedditIcon size={props.size} round />
        </RedditShareButton>
    </div>
}

export default ShareReddit