import { Component } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { BiReset } from 'react-icons/bi'
import { BsInfoCircle } from 'react-icons/bs'
import moment from 'moment'
import './calendar.css'

class Calendar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            info: [
                { id: 0, displayInfo: true, displayMsg: false, msg: 'Click the month to remove selected days.', dir: 'right', val: '30px' }
            ]
        }
    }

    handleMonthChanged(dir) {
        this.props.onMonthChanged(dir)
    }

    handleDaySelected(day) {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayInfo: true, displayMsg: false })
        this.setState({ info })
        this.props.onDaySelected(day)
    }

    handleMonthSelected() {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayMsg: false, displayInfo: false })
        this.setState({ info })
        this.props.onMonthSelected()
    }

    handleInfo(id) {
        let info = [...this.state.info]
        info.splice(id, 1, { ...this.state.info[0], displayMsg: !this.state.info[id].displayMsg })
        this.setState({ info })
    }

    getInfo(id) {
        return <div className='Info-Container' style={{ [this.state.info[id].dir]: this.state.info[0].val }}>
            <div className='Info-Row Info-Text'>{this.state.info[id].msg}</div>
            <div className="Info-Flex-Wrap">
                <div className='Info-Button Info-Button-Save' onClick={() => this.handleInfo(id)}>Got It!</div>
            </div>
        </div>
    }

    getCal() {
        let month = moment(this.props.month)
        let one = month.day()
        let days = []
        let hasEvents = this.props.days || []
        let hasInterest = this.props.altDays || []
        for (let i = 0; i < one; i++) {
            let tmp = moment(month)
            tmp.subtract(one - i, 'days')
            days.push(tmp.date())
        }
        let last = days.length
        let tmp = moment(month).add(1, 'month').subtract(1, 'day').date()
        for (let i = 1; i <= tmp; i++) {
            days.push(i)
        }
        let next = days.length
        let rem = 42 - days.length
        for (let i = 1; i <= rem; i++) {
            days.push(i)
        }
        return <div className='Calendar-Container'>
            <div className='Calendar-Header'>
                <div className='Calendar-Flex'>
                    <div className='Calendar-Header-Text' onClick={() => this.handleMonthSelected(null)}>{moment(this.props.month).format('MMMM YYYY')} <BiReset /></div>
                    {this.state.info[0].displayInfo && <div className='Info-Icon' style={{marginLeft: '3px'}} onClick={() => this.handleInfo(0)}><BsInfoCircle /></div>}
                    {this.state.info[0].displayMsg && this.getInfo(0)}
                </div>
                <div className='Calendar-Buttons-Container'>
                    <div onClick={() => this.handleMonthChanged('back')} style={{ cursor: 'pointer', margin: '0px 5px' }}><FaChevronLeft /></div>
                    <div onClick={() => this.handleMonthChanged('next')} style={{ cursor: 'pointer', margin: '0px 5px' }}><FaChevronRight /></div>
                </div>
            </div>
            <div className='Calendar-Days-Header'>
                {['Su', 'Mn', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((d, didx) => {
                    return <div key={`wd-${didx}`} className='Calendar-WD'>{d}</div>
                })}
            </div>
            <div className='Calendar-Days-Container'>
                {
                    days.filter((day, dayidx) => dayidx < last).map((day, didx) => {
                        return <div key={`day-${didx}`} className={`Calendar-Day Calendar-Day-Off${didx === 0 ? ' Calendar-Topleft' : ''}${didx === 6 ? ' Calendar-Topright' : ''}`}>
                            {day}
                        </div>
                    })
                }
                {
                    days.filter((d, dayidx) => dayidx >= last && dayidx < next).map((day, didx) => {
                        return <div key={`day-${didx}`} className={`Calendar-Day${(didx + last) === 0 ? ' Calendar-Topleft' : ''}${(didx + last) === 6 ? ' Calendar-Topright' : ''}${(didx + last) === 35 ? ' Calendar-Bottomleft' : ''}${(didx + last) === 41 ? ' Calendar-Bottomright' : ''}${hasEvents.indexOf(day) > -1 ? ' Calendar-Day-Event' : (hasInterest.indexOf(day) > -1 ? ' Calendar-Day-Alt' : '')}${this.props.selected === day ? ' Calendar-Day-Sel' : ''}`} onClick={() => this.handleDaySelected(day)}>
                            {day}
                        </div>
                    })
                }
                {
                    days.filter((day, dayidx) => dayidx >= next).map((day, didx) => {
                        return <div key={`day-${didx}`} className={`Calendar-Day Calendar-Day-Off${(didx + next) === 35 ? ' Calendar-Bottomleft' : ''}${(didx + next) === 41 ? ' Calendar-Bottomright' : ''}`}>
                            {day}
                        </div>
                    })
                }
            </div>
        </div>
    }
    render() {
        return <div>{this.getCal()}</div>
    }
}

export default Calendar