import { Component } from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import Carousel from 'react-bootstrap/Carousel'
import withRouter from '../components/withRouter'
import GiglitRed from '../images/giglit-red.svg'
import GiglitBlue from '../images/giglit-blue.svg'
import { FaChevronRight, FaArrowLeft, FaMapMarkedAlt } from 'react-icons/fa'
import { LuCopy, LuCopyCheck } from 'react-icons/lu'
import { BsCalendarFill, BsClockHistory } from 'react-icons/bs'
import './giglit.css'

class Giglit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            splash: true,
            choice: null,
            filteredData: null,
            curr: -1,
            data: null,
            copied: false,
            noun: '',
            iid: null
        }
    }

    componentDidMount() {
        if (!this.props.data || this.props.data.length === 0) {
            setTimeout(() => this.handleNavigate('list', false), 250)
        } else {
            if (this.props.data.filter(d => d.events && d.events.length > 0).length === 0) {
                this.handleChoose('venues')
            } else this.setState({ splash: false })
        }
    }

    handleNavigate(page, history) {
        if (history) this.props.onAddHistory('/giglit')
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        this.props.onBack()
    }

    handleChoose(choice) {
        let filteredData = []
        this.props.data.forEach(d => {
            if (choice === 'venues') {
                filteredData.push(Object.assign({}, { ...d }))
            } else filteredData = [...filteredData, ...d.events]
        })
        console.log(filteredData)
        this.setState({ choice, splash: true, filteredData })
        this.getNouns()
        this.handleNext(filteredData, choice)
        setTimeout(() => {
            this.setState({ splash: false })
            clearInterval(this.state.iid)
        }, 3000)
    }

    handleNext(filteredData, choice) {
        if (this.state.curr === -1) {
            let curr = Math.floor(Math.random() * (filteredData ? filteredData.length : this.state.filteredData.length))
            this.setState({ curr, data: (filteredData || this.state.filteredData)[curr] })
            if ((choice || this.state.choice) === 'events') this.handleGetEvent((filteredData || this.state.filteredData)[curr].id)
        } else {
            if (this.state.filteredData.length > 1) {
                let data = [...this.state.filteredData]
                data.splice(this.state.curr, 1)
                let curr = Math.floor(Math.random() * data.length)
                this.setState({ filteredData: data, curr, data: data[curr], copied: false })
                if ((choice || this.state.choice) === 'events') this.handleGetEvent(data[curr].id)
            } else this.handleNavigate('list', false)
        }
    }

    handleGetEvent(id) {
        const headers = this.props.user && this.props.user.active ? null : { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } }
        API.get('lt', `${this.props.user && this.props.user.active ? 'fan' : ''}event/${id}${this.props.myLoc ? `?lat=${this.props.myLoc.lat}&lng=${this.props.myLoc.lng}` : ''}`, headers)
            .then(resp => {
                this.setState({ data: { ...resp.data.events[0], address: resp.data.address } })
            })
            .catch(err => {
                console.log(err)
                if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
            })
    }

    handleMore() {
        if (this.state.data) this.handleNavigate(`${this.state.choice === 'events' ? `event` : 'venue'}/${this.state.data.id}`, true)
    }

    handleCopy() {
        this.setState({ copied: true })
        navigator.clipboard.writeText(this.getAddress())
    }

    getAddress() {
        if (this.state.data.address) {
            let addr = this.state.data.address
            let address = addr.line1 || ''
            if (addr.line2 && addr.line2.length > 0) address += address.length > 0 ? `, ${addr.line2}` : addr.line2
            if (addr.city && addr.city.length > 0) address += address.length > 0 ? `, ${addr.city}` : addr.city
            if (addr.state && addr.state.length > 0) address += address.length > 0 ? `, ${addr.state}` : addr.state
            if (addr.postalcode && addr.postalcode.length > 4) address += address.length > 0 ? `${addr.state && addr.state.length > 0 ? ' ' : ', '} ${addr.postalcode}` : addr.postalcode
            return address
        } else return null
    }

    getNouns() {
        let idx = -1
        let iid = setInterval(() => {
            if (this.state.choice) {
                if (this.state.filteredData && this.state.filteredData.length > 0) {
                    idx = idx >= this.state.filteredData.length ? 0 : idx + 1
                    if (this.state.choice === 'events') {
                        this.setState({ noun: this.state.filteredData[idx] && this.state.filteredData[idx].summary ? this.state.filteredData[idx].summary : 'Fun' })
                    } else {
                        console.log(this.state.filteredData[idx].category)
                        this.setState({ noun: this.state.filteredData[idx].category })
                    }
                } else this.setState({ noun: 'Fun' })
            } else this.setState({ noun: 'Fun' })
        }, 500)
        this.setState({ iid })
    }

    getChoose() {
        return <div className='Giglit-Choose-Container'>
            <div className='Giglit-Back'><div className='Giglit-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-17px' }}><FaArrowLeft /></div></div></div>
            <div className='Giglit-Choose-Text'>Do we want somewhere to go or something to do?</div>
            <div className='Giglit-Row-Flex'>
                <div className='Giglit-Choose-Button Giglit-venues' onClick={() => this.handleChoose('venues')}>Venues</div>
                <div className='Giglit-Choose-Button Giglit-events' onClick={() => this.handleChoose('events')}>Events</div>
            </div>
        </div>
    }

    getSplash() {
        return <div className='Giglit-Splash-Container'>
            <div className='Giglit-Splash-Text'>GigIn to <div>{this.state.noun ? `${this.state.choice === 'venues' ? `a ${this.state.noun}` : ` ${this.state.noun}`}` : 'Some\n Fun'}</div></div>
            <div className='Giglit-Splash-Image'>{this.state.choice === 'events' ? <img src={GiglitBlue} alt='Giglit' /> : <img src={GiglitRed} alt='Giglit' />}</div>
        </div>
    }

    getGiglit() {
        return <div className='Giglit-Details-Container'>
            <div className='Giglit-Back'><div className='Giglit-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-17px' }}><FaArrowLeft /></div></div></div>
            {this.state.data.imageArray && <Carousel style={{ maxWidth: '100%' }} controls={false} touch={true} indicators={true} interval={2000} fade>
                {
                    this.state.data.imageArray.map((ci, imgidx) => {
                        return <Carousel.Item key={`ci-${imgidx}`}>
                            <img src={`https://localtalent-upload.s3.amazonaws.com/public/${ci}`} className='Giglit-Card-CI' alt={this.state.data.name || this.state.data.title || ''} />
                        </Carousel.Item>
                    })
                }
            </Carousel>}
            <div className='Giglit-Next'><div style={{ position: 'relative', left: '89%' }} className='Giglit-Arrow' onClick={() => this.handleNext()}><div style={{ marginTop: '-17px' }}><FaChevronRight /></div></div></div>
            <div className='Giglit-Details'>
                <div className='Giglit-Row-Flex'><div style={{ borderColor: this.state.choice === 'venues' ? '#f42c74' : '#0f142b' }} className={`Giglit-Addr Giglit-${this.state.choice}`} onClick={() => this.handleCopy()}>{this.getAddress()}<div className='Giglit-Addr-Icon'>{this.state.copied ? <LuCopyCheck /> : <LuCopy />}</div></div></div>
                <div className='Giglit-Row-Flex'><div className='Giglit-Name'>{this.state.data.name || this.state.data.title}</div></div>
                {this.state.data.startTime && this.state.data.stopTime && <div className='Giglit-Row-Flex'>
                    <div className='Giglit-Flex'>
                        <div className='Giglit-Addr-Icon'><BsCalendarFill /></div>
                        <div className='Giglit-Date'>{moment(this.state.data.startTime).format('MMM DD')}</div>
                    </div>
                    <div className='Giglit-Flex'>
                        <div className='Giglit-Addr-Icon'><BsClockHistory /></div>
                        <div className='Giglit-Date'>{moment(this.state.data.startTime).format('h:mm A')}</div>
                    </div>
                    <div className='Giglit-Flex'>
                        <div className='Giglit-Addr-Icon'><BsCalendarFill /></div>
                        <div className='Giglit-Date'>{moment(this.state.data.stopTime).format('MMM DD')}</div>
                    </div>
                    <div className='Giglit-Flex'>
                        <div className='Giglit-Addr-Icon'><BsClockHistory /></div>
                        <div className='Giglit-Date'>{moment(this.state.data.stopTime).format('h:mm A')}</div>
                    </div>
                </div>}
                <div className='Giglit-Row-Flex'><div className='Giglit-Cat'>{this.state.data.category || 'Event'}</div></div>
                <div className='Giglit-Row-Flex'><div style={{ borderColor: this.state.choice === 'venues' ? '#f42c74' : '#0f142b' }} className={`Giglit-More Giglit-${this.state.choice}`} onClick={() => this.handleMore()}>View More</div></div>
                <div className='Giglit-Row-Flex'>
                    {this.state.data.distance && <div className='Giglit-Dist'><div className='Giglit-Addr-Icon'><FaMapMarkedAlt /></div>{this.state.data.distance.toFixed(1)}</div>}
                    {this.state.choice && this.state.choice === 'events' && this.state.data && this.state.data.events && this.state.data.events[0] && this.state.data.events[0].weather && <div className='Giglit-Dist'>
                        <img src={`https://openweathermap.org/img/wn/${this.state.data.events[0].weather[0].weather[0].icon}@2x.png`} className='Giglit-Weather-Img' alt={this.state.data.events[0].weather[0].weather[0].main} />
                        {this.state.data.events[0].weather[0].summary}
                    </div>}
                </div>

            </div>
        </div>
    }

    render() {
        //console.log(this.state, this.props)
        return <div className={`Giglit-Container ${this.state.choice ? `Giglit-${this.state.choice}` : 'Giglit-Default'}`}>
            {
                this.state.splash ? this.getSplash() : (this.state.choice ? this.getGiglit() : this.getChoose())
            }
        </div>
    }

}

export default withRouter(Giglit)