import { Component } from 'react'
import withRouter from '../components/withRouter'
import TopMenu from '../components/topMenu'
import BottomMenu from '../components/bottomMenu'
import Card from '../components/card'
import { API } from 'aws-amplify'
import moment from 'moment'
import { TbFilterSearch } from 'react-icons/tb'
import './cardList.css'

class CardList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            lastSearch: { dist: 25 },
            loc: '',
            lastLoc: '',
            locIsGood: false,
            dist: 25,
            byDist: false,
            byDate: false,
            options: null,
            month: moment().date(1).format('YYYY-MM-DD'),
            days: [moment().date()],
            start: moment().format('YYYY-MM-DD'),
            end: moment().add(1, 'day').format('YYYY-MM-DD'),
            pref: null,
            searchChanged: false,
            view: 'map',
            menu: {
                left: 'find',
                right: 'map',
                giglit: 'blue',
                mode: 'list'
            },
            filteredData: null,
            zoom: 11,
            node: '',
            nodeName: '',
            cat: [],
            findCards: null,
            nodes: null,
            displaySettings: false,
            displaySearch: false,
            displayFind: false,
            displayGiglit: false,
            giglit: null,
            getnew: false,
            next: null
        }

        this.handleLocationCheck = this.handleLocationCheck.bind(this)
        this.handleLocChanged = this.handleLocChanged.bind(this)
        this.handleDistChanged = this.handleDistChanged.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleDayChanged = this.handleDayChanged.bind(this)
        this.handleMonthChanged = this.handleMonthChanged.bind(this)
        this.handleMonthSelected = this.handleMonthSelected.bind(this)
        this.handleOptionSelect = this.handleOptionSelect.bind(this)
        this.handleGetMyLoc = this.handleGetMyLoc.bind(this)
        this.handleFollow = this.handleFollow.bind(this)
        this.handleInterest = this.handleInterest.bind(this)
        this.handleAttending = this.handleAttending.bind(this)
        this.handleRightMenu = this.handleRightMenu.bind(this)
        this.handleGiglit = this.handleGiglit.bind(this)
        this.handleGoToCard = this.handleGoToCard.bind(this)
        this.handleFindChanged = this.handleFindChanged.bind(this)
        this.handleFindCat = this.handleFindCat.bind(this)
        this.handleFindName = this.handleFindName.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleDisplaySearch = this.handleDisplaySearch.bind(this)
        this.handleDisplayFind = this.handleDisplayFind.bind(this)
        this.handleByDist = this.handleByDist.bind(this)
        this.handleByDate = this.handleByDate.bind(this)
        this.handleNodeSelected = this.handleNodeSelected.bind(this)
        this.handleFindCards = this.handleFindCards.bind(this)
        this.handleResetOptions = this.handleResetOptions.bind(this)
        this.handleResetFind = this.handleResetFind.bind(this)
    }

    componentDidMount() {
        const { search } = this.props
        const queryParams = new URLSearchParams(search.search)
        const refresh = queryParams.get('refresh') || false
        if (!this.props.data || refresh) {
            if (this.props.myloc) {
                this.setState({ center: this.props.myLoc, dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist, isLoading: false })
                this.getData(this.props.myLoc, this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist)
            } else {
                const gl = navigator.geolocation
                gl.getCurrentPosition(position => {
                    this.props.onMyLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                    this.setState({ center: this.props.myLoc, dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist, isLoading: false })
                    this.getData({ lat: position.coords.latitude, lng: position.coords.longitude }, this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist)
                }, err => {
                    if (this.props.user && this.props.user.defaultLoc) {
                        this.props.onMyLoc(this.props.user.defaultLoc)
                        this.setState({ center: this.props.user.defaultLoc, isLoading: false, dist: this.props.user.dist || this.state.dist })
                        this.getData(this.props.user.defaultLoc, this.props.user.dist || this.state.dist)
                    } else this.setState({ isLoading: false })
                })
            }
        } else {
            if (this.props.filter) {
                this.setState({
                    isLoading: false,
                    center: this.props.myLoc,
                    loc: this.props.filter.loc,
                    dist: this.props.filter.dist,
                    start: this.props.filter.start,
                    end: this.props.filter.end,
                    days: this.props.filter.days,
                    pref: this.props.filter.pref
                })
            } else {
                this.setState({ isLoading: false, center: this.props.myLoc })
            }
            this.handleFilter(null, this.props.filter ? (this.props.filter.pref || null) : this.state.pref)
        }
    }

    handleNavigate(page) {
        this.props.onAddHistory('/list')
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleDisplaySettings() {
        this.setState({ displayFind: false, displaySearch: false, displaySettings: !this.state.displaySettings })
    }

    handleDisplaySearch() {
        if (this.state.displaySearch) {
            if (this.state.nodes && this.state.nodes.length > 0) {
                this.handleSearch()
            } else {
                this.getData()
            }
            this.setState({ displaySettings: false, displaySearch: false, displayFind: false, menu: { ...this.state.menu, mode: 'fan' } })
        } else {
            if (!this.state.options && this.props.isAuthenticated) {
                API.get('lt', 'options?prop=node')
                    .then(resp => this.setState({ options: resp.data || [] }))
                    .catch(err => {
                        console.log(err)
                        if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                    })
            }
            this.setState({ displayFind: false, displaySettings: false, displaySearch: true, menu: { ...this.state.menu, mode: null } })
        }
    }

    handleDisplayFind() {
        if (this.props.isAuthenticated) {
            if (this.state.displayFind) {
                this.setState({ displaySearch: false, displaySettings: false, displayFind: false })
                this.getData()
            } else {
                if (!this.state.options) {
                    API.get('lt', 'options?prop=node')
                        .then(resp => this.setState({ options: resp.data || [] }))
                        .catch(err => {
                            console.log(err)
                            if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                        })
                }
                this.setState({ displaySearch: false, displaySettings: false, displayFind: true, nodes: null })
            }
        } else this.handleNavigate('main')
    }

    handleGiglit() {
        if (this.props.data && this.props.data.length > 1) this.handleNavigate('giglit')
    }

    handleRightMenu() {
        this.handleNavigate('')
    }

    handleFindChanged(event) {
        this.setState({ [event.target.name]: event.target.value.replace(/[^a-zA-Z0-9- ]/g, "") })
    }

    handleFindCat(event) {
        let cat = [...this.state.cat]
        if (event.target.checked) {
            cat.push(event.target.value)
        } else cat.splice(cat.indexOf(event.target.value), 1)
        this.setState({ cat })
    }

    handleFindName(event) {
        this.setState({ nodeName: event.target.value })
    }

    handleGoToCard(id, type) {
        switch (type) {
            case 'venue':
                this.handleNavigate(`venue/${id}`)
                break
            case 'contributor':
                this.handleNavigate(`contributor/${id}`)
                break
            default:
                this.handleNavigate(`event/${id}`)
        }
    }

    handleModeChange(mode) {
        this.setState({ displaySettings: false })
        if (mode === 'contactus') {
            this.handleNavigate('contactus')
        } else {
            if (mode !== 'fan') {
                this.props.setMode(mode)
                this.handleNavigate(`my/${mode}s`)
            }
        }
    }

    handleLocChanged(event) {
        this.setState({ locIsGood: false, loc: event.target.value.replace(/[^a-zA-Z0-9- ]/g, "") })
    }

    handleLocationCheck() {
        if (this.state.loc && this.state.loc !== this.state.lastLoc) {
            API.get('lt', `geo?address=${this.state.loc.replace(/#/g, '%23')}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    }
                })
                .catch(e => {
                    this.setState({ loc: '', locIsGood: false })
                })
        }
    }

    handleGetMyLoc() {
        const gl = navigator.geolocation
        gl.getCurrentPosition(position => {
            API.get('lt', `geo?coord=${position.coords.latitude},${position.coords.longitude}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    } else {
                        this.setState({ locIsGood: false, loc: 'Not Found' })
                    }
                })
        }, err => {
            console.log(err)
        })
    }

    handleDistChanged(event) {
        let dist = event.target.value === "0" ? "1" : (event.target.value)
        this.setState({ dist })
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile')
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications')
    }

    handleSearch() {
        let doIt = this.state.getnew
        this.setState({ findCards: null, getnew: false })
        if (this.state.lastSearch) {
            if (this.state.lastSearch.dist && this.state.lastSearch.dist !== this.state.dist) {
                doIt = true
            } else {
                if (this.state.searchChanged) {
                    doIt = true
                } else if (!this.state.lastSearch.start ||
                    !this.state.lastSearch.end ||
                    !this.state.start ||
                    !this.state.end ||
                    moment(this.state.start).isBefore(moment(this.state.lastSearch.start)) ||
                    moment(this.state.end).isAfter(moment(this.state.lastSearch.end))) {
                    doIt = true
                }
            }
            if (doIt) {
                this.props.setFilter({
                    loc: this.state.loc,
                    dist: this.state.dist,
                    start: this.state.start,
                    end: this.state.end,
                    days: this.state.days,
                    pref: this.state.pref
                })
                this.getData()
            } else this.handleFilter()
        } else this.getData()
    }

    handleOptionSelect(node, cat) {
        if (node === 'amenities') {
            let amenities = this.state.pref && this.state.pref.amenities ? this.state.pref.amenities : []
            let idx = amenities.indexOf(cat)
            if (idx > -1) {
                amenities.splice(idx, 1)
            } else amenities.push(cat)
            this.setState({ pref: { ...this.state.pref, amenities } })
        } else {
            if (this.state.pref && this.state.pref[node]) {
                let cats = [...this.state.pref[node]]
                let idx = cats.indexOf(cat)
                if (idx === -1) {
                    cats.push(cat)
                } else cats.splice(idx, 1)
                this.setState({ pref: { ...this.state.pref, [node]: cats } })
            } else {
                if (this.state.pref && this.state.pref.amenities) {
                    this.setState({ pref: { amenities: this.state.pref.amenities, [node]: [cat] } })
                } else this.setState({ pref: { [node]: [cat] } })
            }
        }
    }

    handleResetOptions() {
        this.setState({ pref: null })
    }

    handleMonthChanged(dir) {
        let month = moment(this.state.month)
        let days = []
        if (dir === 'back') {
            month.subtract(1, 'month')
        } else if (dir === 'next') {
            month.add(1, 'month')
        }
        let start = this.state.start ? moment(this.state.start) : null
        let end = this.state.end ? moment(this.state.end) : null
        if (start && end) {
            if (end.isBefore(month) || start.isAfter(moment(month).add(1, 'month'))) {
                days = []
            } else if (start.isBefore(month)) {
                if (end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = 1; d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else if (end.isBetween(month, moment(month).add(1, 'month'))) {
                    for (let d = 1; d < end.date(); d++) {
                        days.push(d)
                    }
                }
            } else if (start.isSame(month) || start.isBetween(month, moment(month).add(1, 'month'))) {
                if (end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = start.date(); d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else {
                    for (let d = start.date(); d < end.date(); d++) {
                        days.push(d)
                    }
                }
            }
        } else console.log('no days')
        this.setState({ days, month: month.format('YYYY-MM-DD'), start: start ? start.format('YYYY-MM-DD') : null, end: end ? end.format('YYYY-MM-DD') : null })
    }

    handleMonthSelected() {
        this.setState({ days: [], start: null, end: null })
    }

    handleDayChanged(day) {
        if (this.state.start) {
            let month = moment(this.state.month)
            let sel = moment(this.state.month).date(day)
            let start = moment(this.state.start)
            let end = moment(this.state.end)
            let days = []
            if (sel.isBefore(end)) {
                start = sel
            } else {
                end = moment(sel).add(1, 'day')
            }
            if (start.isBefore(month)) {
                if (end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = 1; d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else if (end.isBetween(month, moment(month).add(1, 'month'))) {
                    for (let d = 1; d < end.date(); d++) {
                        days.push(d)
                    }
                }
            } else if (start.isSame(month) || start.isBetween(month, moment(month).add(1, 'month'))) {
                if (end.isSame(moment(month).add(1, 'month')) || end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = start.date(); d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else {
                    for (let d = start.date(); d < end.date(); d++) {
                        days.push(d)
                    }
                }
            }
            this.setState({ days, start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD'), searchChanged: true })
        } else this.setState({ start: moment(this.state.month).date(day).format('YYYY-MM-DD'), end: moment(this.state.month).date(day).add(1, 'day').format('YYYY-MM-DD'), days: [day], searchChanged: true })
    }

    handleByDist() {
        this.setState({ byDist: !this.state.byDist })
    }

    handleByDate() {
        this.setState({ byDate: !this.state.byDate })
    }

    handleAttending(id) {
        if (this.props.isAuthenticated) {
            API.put('lt', `interest/${id}`, { body: { status: 'attending', local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    let idx = this.state.filteredData.events.findIndex(e => e.events[0].id === id)
                    if (idx > -1) {
                        let iamattending = this.state.filteredData.events[idx].events[0].iamattending || false
                        let iaminterested = this.state.filteredData.events[idx].events[0].iaminterested || false
                        let attending = this.state.filteredData.events[idx].events[0].attending || 0
                        iamattending = !iamattending
                        attending = iaminterested ? attending : (iamattending ? attending + 1 : attending - 1)
                        iaminterested = false
                        let event = this.state.filteredData.events[idx]
                        event.events[0] = { ...event.events[0], iamattending, iaminterested, attending }
                        let events = [...this.state.filteredData.events]
                        events.splice(idx, 1, event)
                        this.setState({ filteredData: { ...this.state.filteredData, events }, getnew: true })
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')

                })
        } else {
            this.handleNavigate('main')
        }
    }

    handleInterest(id) {
        if (this.props.isAuthenticated) {
            API.put('lt', `interest/${id}`, { body: { status: 'interested', local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    let idx = this.state.filteredData.events.findIndex(e => e.events[0].id === id)
                    if (idx > -1) {

                        let iamattending = this.state.filteredData.events[idx].events[0].iamattending || false
                        let iaminterested = this.state.filteredData.events[idx].events[0].iaminterested || false
                        let interested = this.state.filteredData.events[idx].events[0].interested || 0
                        console.log('interested', iaminterested, !iaminterested, interested)
                        iaminterested = !iaminterested
                        interested = iamattending ? interested : (iaminterested ? interested + 1 : interested - 1)
                        iamattending = false
                        let event = this.state.filteredData.events[idx]
                        event.events[0] = { ...event.events[0], iamattending, iaminterested, interested }
                        let events = [...this.state.filteredData.events]
                        events.splice(idx, 1, event)
                        this.setState({ filteredData: { ...this.state.filteredData, events }, getnew: true })
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        } else {
            this.handleNavigate('main')
        }
    }

    handleFollow(id) {
        if (this.props.isAuthenticated) {
            //if it is a venue, proceed - if not, need to do the same for node
            API.put('lt', `interest/${id}`, { body: { status: 'following' } })
                .then(resp => {
                    let idx = this.state.filteredData.venues.findIndex(v => v.id === id)
                    if (idx > -1) {
                        let venue = this.state.filteredData.venues[idx]
                        venue.iamfollowing = venue.iamfollowing ? false : true
                        venue.followers = venue.iamfollowing ? ((venue.followers || 0) + 1) : venue.followers - 1
                        let venues = [...this.state.filteredData.venues]
                        venues.splice(idx, 1, venue)
                        let pidx = this.props.data.findIndex(d => d.id === id)
                        if (pidx > -1) this.props.setData([...this.props.data.slice(0, pidx), venue, ...this.props.data.slice(pidx + 1)])
                        this.setState({ filteredData: { ...this.state.filteredData, venues } })
                    } else {
                        let idx = this.state.filteredData.contributors.findIndex(c => c.id === id)
                        if (idx > -1) {
                            let contributor = this.state.filteredData.contributors[idx]
                            contributor.iamfollowing = contributor.iamfollowing ? false : true
                            contributor.followers = contributor.iamfollowing ? ((contributor.followers || 0) + 1) : contributor.followers - 1
                            let contributors = [...this.state.filteredData.contributors]
                            contributors.splice(idx, 1, contributor)
                            this.setState({ filteredData: { ...this.state.filteredData, contributors }, getnew: true })
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        } else {
            this.handleNavigate('main')
        }
    }

    handleFilter(d, p) {
        let filteredData = { events: [], venues: [], contributors: [] }
        if (d || this.props.data) {
            let data = d || JSON.parse(JSON.stringify(this.props.data))
            if (d || p) {                
                if (this.state.nodes) {
                    data.forEach(d => {
                        filteredData[d.nodetype === 'Venue' ? 'venues' : 'contributors'].push(d)
                        if (d.events && d.events.length > 0) {
                            d.events.forEach(e => {
                                filteredData.events.push({ ...d, events: [e] })
                                if (e.nodes && e.nodes.length > 0) {
                                    //filteredData.contributors = [...filteredData.contributors, ...e.nodes]
                                    e.nodes.forEach(n => {
                                        let idx = filteredData.contributors.findIndex(c => c.id === n.id)
                                        if (idx === -1) filteredData.contributors.push(n)
                                    })
                                }
                            })
                        }
                    })
                } else {
                    let pref = p || this.state.pref
                    const amenities = pref ? pref['amenities'] : null
                    const idx = pref ? Object.keys(pref).findIndex(p => p !== 'amenities') : -1
                    const node = idx > -1 ? Object.keys(pref)[idx] : null
                    const categories = idx > -1 ? Object.values(pref)[idx] : null
                    let end = this.props.filter ? this.props.filter.end : this.state.end
                    let start = this.props.filter ? this.props.filter.start : this.state.start
                    let dist = this.props.filter && this.props.filter.dist ? this.props.filter.dist : this.state.dist
                    data.filter(d => d.nodetype === 'Venue').forEach(v => {
                        let vkeep = null
                        if (v.events) {
                            if (!pref || !pref['Venue']) {
                                v.events && v.events.filter(e => (!end || moment(e.startTime).isBefore(moment(end))) &&
                                    (!start || moment(e.stopTime).isAfter(moment(start)))).forEach(e => {
                                        let keep = true
                                        if (amenities) {
                                            amenities.forEach(a => {
                                                if (e.amenities.indexOf(a) === -1) keep = false
                                            })
                                        }
                                        if (keep) {
                                            if (node) {
                                                keep = e.searchSlots && e.searchSlots.filter(s => s.node === node && pref[node].indexOf(s.category) > -1).length > 0
                                                if(!keep && (!e.searchSlots || e.searchSlots.length === 0) && categories && e.summary && categories.indexOf(e.summary) > -1) keep = true
                                            }
                                        }
                                        if (keep) {
                                            filteredData.events.push({ ...v, events: [e] })
                                            if (e.nodes) {
                                                e.nodes.forEach(n => {
                                                    let idx = filteredData.contributors.findIndex(c => c.id === n.id)
                                                    if (idx === -1) filteredData.contributors.push(n)
                                                })
                                            }
                                            if (!vkeep) {
                                                vkeep = [v.id]
                                            } else if (vkeep.indexOf(v.id) === -1) vkeep.push(v.id)
                                        }
                                    })
                            }
                        }
                        if (!node || (node === 'Venue' && pref[node].indexOf(v.category) > -1)) {
                            let keep = true
                            if (amenities) {
                                amenities.forEach(a => {
                                    if (v.amenities.indexOf(a) === -1) keep = false
                                })
                            }
                            if (keep) filteredData.venues.push(v)
                        } else if (node && node !== 'Venue') {
                            if (vkeep) data.filter(d => d.nodetype === 'Venue').forEach(v => {
                                if (vkeep.indexOf(v.id) > -1) filteredData.venues.push(v)
                            })
                        } else filteredData.venues.push(v)
                    })
                    filteredData.venues.sort((a, b) => {
                        if ((a.distance || dist) > (b.distancedist)) return 1
                        if ((a.distance || dist) < (b.distance || dist)) return -1
                        return 0
                    })
                    filteredData.events.sort((a, b) => {
                        if ((a.distance || dist) > (b.distance || dist)) return 1
                        if ((a.distance || dist) < (b.distance || dist)) return -1
                        return 0
                    })
                }
            } else {
                //coming over with prior data, no filters
                data.forEach(d => {
                    if (d.nodetype === 'Venue') {
                        filteredData.venues.push(d)
                        if (d.events && d.events.length > 0) {
                            d.events.forEach(e => {
                                filteredData.events.push({ ...d, events: [e] })
                                if (e.nodes && e.nodes.length > 0) {
                                    e.nodes.forEach(n => {
                                        let idx = filteredData.contributors.findIndex(c => c.id === n.id)
                                        if (idx === -1) filteredData.contributors.push(n)
                                    })
                                }
                            })
                        }
                    } else {
                        let idx = filteredData.contributors.findIndex(c => c.id === d.id)
                        if (idx === -1) filteredData.contributors.push(d)
                    }
                })
            }
        }
        this.setState({ filteredData })
    }

    handleNodeSelected(nid) {
        if (this.state.nodes) {
            let nodes = [...this.state.nodes]
            let idx = nodes.indexOf(nid)
            if (idx > 0) {
                nodes.splice(idx, 1)
            } else nodes.push(nid)
            this.setState({ nodes })
        } else this.setState({ nodes: [nid] })
    }

    handleCardSelected(type, id) {
        console.log('navigate to card page')
    }

    handleFindCards() {
        if (this.props.isAuthenticated && this.state.node.length > 0) {
            if (this.state.nodes && this.state.nodes.length > 0) {
                this.setState({ displaySearch: false, displaySettings: false, displayFind: false })
                this.getData()
            } else {
                this.setState({ isLoading: true })
                API.get('lt', `nodes?node=${this.state.node}${this.state.cat.length > 0 ? `&cat=${this.state.cat}` : ''}${this.state.nodeName.length > 0 ? `&name=${this.state.nodeName}` : ''}${this.state.byDist && this.props.myLoc ? `&lat=${this.props.myLoc.lat}&lng=${this.props.myLoc.lng}&dist=${this.state.dist}` : ''}${this.state.byDate ? `&start=${this.state.start}&end=${this.state.end}` : ''}`)
                    .then(resp => {
                        this.setState({ findCards: resp.data || null, isLoading: false })
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ isLoading: false })
                        if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                    })
            }
        }
    }

    handleResetFind() {
        this.setState({ nodes: null, node: '', cat: [], nodeName: '', byDate: false, byDist: false, findCards: null })
    }

    getData(coord, reset, dist) {
        this.props.setData([])
        if (coord || this.props.myLoc || reset) {
            this.setState({ searchChanged: false, filteredData: null })
            let qry = this.state.nodes && this.state.nodes.length > 0 ?
                `nid=${this.state.nodes.toString()}` :
                `lat=${coord ? coord.lat : this.props.myLoc.lat}&lng=${coord ? coord.lng : this.props.myLoc.lng}&dist=${dist || this.state.dist}`
            if (this.state.nodes) {
                if (this.state.byDist) qry += `&lat=${coord ? coord.lat : this.props.myLoc.lat}&lng=${coord ? coord.lng : this.props.myLoc.lng}&dist=${dist || this.state.dist}`
                if (this.state.byDate) {
                    qry += `&start=${this.state.start}&end=${this.state.end}`
                } else qry += `&start=${moment().format('YYYY-MM-DD HH:mm')}`
            } else if (this.state.start && this.state.end) {
                qry += `&start=${this.state.start}&end=${this.state.end}`
            } else qry += `&start=${moment().format('YYYY-MM-DD HH:mm')}`
            if (this.state.next) qry += `&next=${this.state.next}`
            const headers = this.props.isAuthenticated ? null : { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } }
            API.get('lt', `${this.props.isAuthenticated ? 'fanstuff' : 'userstuff'}?${qry}`, headers)
                .then(resp => {
                    this.props.setData(resp.data || [])
                    this.setState({ lastSearch: { start: this.state.start, end: this.state.end, dist: this.state.dist }, isLoading: false, next: resp.data.next || null })
                    this.handleFilter(resp.data || [])
                })
                .catch(err => {
                    console.log(err, headers)
                    this.setState({ isLoading: false, next: null })
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        }
    }

    getNoResults() {
        return <div className='List-NoResults-Container'>
            <div className='List-NoResults-Icon'><TbFilterSearch /></div>
            <div className='List-NoResults-Text'>No Matching Results</div>
            <div className='List-NoResults-Text-2'>Refine your search criteria</div>
        </div>
    }

    render() {
        //console.log(this.state, this.props)
        return <div className='List-Container'>
            <TopMenu
                mode='fan'
                onMode={this.handleModeChange}
                loc={this.state.loc}
                locIsGood={this.state.locIsGood}
                onLocCheck={this.handleLocationCheck}
                onLocChanged={this.handleLocChanged}
                dist={this.state.dist}
                onDistChange={this.handleDistChanged}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSearch={this.handleDisplaySearch}
                onSettings={this.handleDisplaySettings}
                options={this.state.options}
                month={this.state.month}
                days={this.state.days}
                onMonthChanged={this.handleMonthChanged}
                onMonthSelected={this.handleMonthSelected}
                onDaySelected={this.handleDayChanged}
                onOptionsSelected={this.handleOptionSelect}
                pref={this.state.pref}
                search
                range
                calendar
                onGetMyLoc={this.handleGetMyLoc}
                displaySearch={this.state.displaySearch}
                onResetOptions={this.handleResetOptions}
                displaySettings={this.state.displaySettings} />
            <div className='List-Cards'>
                {
                    this.state.filteredData && this.state.filteredData.events ? this.state.filteredData.events.map((e, eidx) => {
                        return <div key={`cevent-${eidx}`} style={{ marginBottom: '5px' }}><Card type='event' data={e} near={this.props.user && this.props.user.pref && this.props.user.pref.distance && this.props.user.pref.distance >= (this.state.event.dist || 0)} onSelected={this.handleGoToCard} onInterest={this.handleInterest} onAttending={this.handleAttending} /></div>
                    }) : null
                }
                {
                    this.state.filteredData && this.state.filteredData.contributors ? this.state.filteredData.contributors.map((c, cidx) => {
                        return <div key={`ccontributor-${cidx}`} style={{ marginBottom: '5px' }}><Card type='contributor' data={c} onSelected={this.handleGoToCard} onFollow={this.handleFollow} /></div>
                    }) : null
                }
                {
                    this.state.filteredData && this.state.filteredData.venues ? this.state.filteredData.venues.map((v, vidx) => {
                        return <div key={`cvenue-${vidx}`} style={{ marginBottom: '5px' }}><Card type='venue' data={v} near={this.props.user && this.props.user.pref && this.props.user.pref.distance && this.props.user.pref.distance >= (this.state.event.dist || 0)} onSelected={this.handleGoToCard} onFollow={this.handleFollow} /></div>
                    }) : null
                }
                {
                    this.state.filteredData && (this.state.filteredData.events.length + this.state.filteredData.venues.length + this.state.filteredData.contributors.length === 0) && this.getNoResults()
                }
            </div>
            <BottomMenu
                menu={this.state.menu}
                onLeft={this.handleDisplayFind}
                onGiglit={this.handleGiglit}
                onRight={this.handleRightMenu}
                displayFind={this.state.displayFind}
                options={this.state.options}
                byDist={this.state.byDist}
                onByDist={this.handleByDist}
                byDate={this.state.byDate}
                onByDate={this.handleByDate}
                isFinding={this.state.isLoading}
                onFind={this.handleFindCards}
                onFindChanged={this.handleFindChanged}
                onFindNameChanged={this.handleFindName}
                onCat={this.handleFindCat}
                node={this.state.node}
                cat={this.state.cat}
                nodeName={this.state.nodeName}
                findCards={this.state.findCards}
                nodes={this.state.nodes}
                onCardSelected={this.handleNodeSelected}
                onReset={this.handleResetFind} />
        </div>
    }
}

export default withRouter(CardList)