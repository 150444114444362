import React, { Component } from 'react'
import Form from "react-bootstrap/Form"
import LoaderButton from '../components/loaderButton'
//import FacebookButton from '../components/FacebookButton'
import moment from 'moment'
import { MdChevronLeft } from 'react-icons/md'
import { Auth, API } from 'aws-amplify'
import withRouter from '../components/withRouter'
import Checkmark from '../images/checkmark.svg'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import './signup.css'

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            pw: '',
            npw: '',
            cpw: '',
            code: '',
            isLoading: false,
            curr: "1",
            pwUpateIsGood: false,
            isReset: false,
            msg: '',
            displayMsg: false,
            showPW: false,
            showCPW: false,
            sent: null
        }
        this.handleFbLogin = this.handleFbLogin.bind(this)
    }

    componentDidMount() {
        const { search } = this.props
        const queryParams = new URLSearchParams(search.search)
        if (queryParams.get('page') && queryParams.get('page') === "reset") {
            this.setState({ curr: "2", isReset: true })
            if (this.props.user && this.props.user.email) this.setState({ email: this.props.user.email })
        }
        if (this.props.isAuthenticated || this.props.user) {
            Auth.signOut({ global: true })
                .then(resp => {
                    this.props.setUser(null)
                    this.props.userHasAuthenticated(false)
                })
                .catch(e => console.log(e))
        }
    }

    formIsValid() {
        let testpw = /[\d\W!@#$%^&*()_+-=[\]{}|<>]/
        switch (this.state.curr) {
            case "1":
                return this.state.email.length > 5 && this.state.pw.length > 7 && testpw.test(this.state.pw)
            case "2":
                return this.state.email.length > 5
            case "3":
                return this.state.pw.length > 7 && this.state.cpw === this.state.pw && this.state.code.length > 5
            default:
                return false
        }
    }

    handleShowPW() {
        this.setState({ showPW: !this.state.showPW })
    }

    handleShowCPW() {
        this.setState({ showCPW: !this.state.showCPW })
    }

    handleFbLogin = async () => {
        const { navigate } = this.props
        this.setState({ isLoading: true })
        try {
            let user = await API.get('lt', `user`)
            this.props.setUser(user.data ? user.data : null)
            this.props.userHasAuthenticated(true)
            navigate('/')
        } catch (e) {
            console.log(e)
            this.setState({ isLoading: false, msg: e.message, displayMsg: true })
        }
    }

    handleSubmit = async event => {
        event.preventDefault()
        this.setState({ isLoading: true })
        try {
            await Auth.signIn(this.state.email, this.state.pw)
            //let currUser = await Auth.currentAuthenticatedUser()
            let user = await API.get('lt', `user`)
            this.props.setUser(user.data ? user.data : null)
            this.props.userHasAuthenticated(true)
            this.props.onBack()
        } catch (e) {
            console.log(e)
            this.setState({ isLoading: false, msg: e.message, displayMsg: true })
        }
    }

    handleSubmitEmail = async event => {
        event.preventDefault()
        this.setState({ isLoading: true })
        try {
            await Auth.forgotPassword(this.state.email)
            this.setState({ isLoading: false, curr: '3', sent: moment().add(5, 'minutes'), msg: 'A Reset Code has been requested and will be sent to your email.  This usually only takes a minute, but could take much longer.  Please be patient.', displayMsg: true })
        } catch (e) {
            console.log(e)
            this.setState({ isLoading: false, msg: e.message, displayMsg: true })
        }
    }

    handleSubmitNewPW = async event => {
        event.preventDefault()
        this.setState({ isLoading: true })
        try {
            await Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.pw)
            this.setState({ pwUpdateIsGood: true })
            setTimeout(() => this.setState({ isLoading: false, curr: '1', pwUpateIsGood: false }), 750)
        } catch (e) {
            console.log(e)
            this.setState({ isLoading: false, msg: e.message, displayMsg: true })
        }
    }

    handleChange = event => {
        if (event.target.id === 'pw' || event.target.id === 'cpw') {
            this.setState({ [event.target.id]: event.target.value.replace(/[^\d\w!@#$%^&*()_+-=[\]{}|<>]/g, "").substring(0, 127) })
        } else this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@.]/g, "").toLowerCase() })
    }

    handleBack() {
        const { navigate } = this.props
        navigate('/')
    }

    handleForgotPWSelect() {
        this.setState({ curr: '2' })
    }

    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false })
    }

    handleSignUp() {
        const { navigate } = this.props
        navigate('/signup')
    }

    handleResendCode() {
        if (this.state.sent && moment().isAfter(this.state.sent)) {
            Auth.resendSignUp(this.state.email)
                .then(resp => {
                    this.setState({ codeSent: true, sent: moment().add(5, 'minutes'), msg: 'A new Reset Code has been requested. Any prior Codes received will be invalid.', displayMsg: true })
                })
                .catch(e => {
                    console.log(e)
                    this.setState({ msg: e.message, displayMsg: true })
                })
        } else {
            this.setState({ msg: 'Please allow at least 5 minutes before requesting a new Reset Code.', displayMsg: true })
        }
    }

    getValidation(field) {
        switch (field) {
            case 'email':
                return this.state.email.length > 2 && this.state.email.indexOf('@') > 0 && this.state.email.indexOf('.') > 1
            case 'pw':
                return this.state.pw.length > 7 && /[\d\W!@#$%^&*()_+-=[\]{}|<>]/.test(this.state.pw)
            case 'cpw':
                return this.state.cpw.length > 7 && this.state.cpw === this.state.pw
            case 'code':
                return this.state.code.length > 3 && /\d+/.test(this.state.code)
            default:
                return false
        }
    }

    pwIsStrong(part) {
        switch (part) {
            case 'length':
                return this.state.pw.length > 7
            case 'upper':
                const upper = /[A-Z]/
                return upper.test(this.state.pw)
            case 'number':
                const num = /\d/
                return num.test(this.state.pw)
            case 'special':
                const spec = /[-=!@#$%&*()_{}|<>\][+^]/
                return spec.test(this.state.pw)
            default: return false
        }
    }

    getMessage() {
        return <div className='Signup-Confirm'>
            <div className='Signup-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Signup-Row Node-Flex-Wrap">
                <div className='Signup-Confirm-Button' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    getLoginForm() {
        return (
            <div className="Signup Login">
                <div className='Login-Heading Login-Heading-Container Signup-Group'><div className='Signup-Heading-Item Signup-Heading-Icon' onClick={() => this.handleBack()}><MdChevronLeft /></div><div className='Login-Heading-Item'>Log In</div></div>
                <form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="email" className="form-group form-group-lg Signup-Group">
                        <Form.Label className='Signup-Input'>Email</Form.Label>
                        <Form.Control
                            autoFocus
                            placeholder="Enter your email address"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            isValid={this.getValidation('email')}
                            className='Login-Field'
                        />
                    </Form.Group>
                    <Form.Group controlId="pw" className="form-group form-group-lg Signup-Group">
                        <Form.Label className='Signup-Input'>Password</Form.Label>
                        <div className='Signup-Heading-Container'><Form.Control
                            placeholder="Enter your password"
                            value={this.state.pw}
                            onChange={this.handleChange}
                            type={this.state.showPW ? "text" : "password"}
                            isValid={this.getValidation('pw')}
                            className='Login-Field'
                        /><div className='Signup-Icon' onClick={() => this.handleShowPW()}>{this.state.showPW ? <FaEye /> : <FaEyeSlash />}</div></div>
                    </Form.Group>
                    <LoaderButton
                        size="lg"
                        disabled={!this.formIsValid()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Login"
                        loadingText="Logging in..."
                        className='Login-Button'
                    />
                </form>
                <div className='Signup-Heading Signup-Flex-Spread'>
                    <div className='Signup-Hint Login-Link'><span className='Signup-Link' onClick={() => this.handleForgotPWSelect()}>Forgot Password</span></div>
                    <div className='Signup-Hint Login-Link'><span className='Signup-Link' onClick={() => this.handleForgotPWSelect()}>Change Password</span></div>
                </div>
                <div className='Signup-Heading'>
                    <div className='Signup-Hint Login-Link'><span className='Signup-Link' onClick={() => this.handleSignUp()}>Create Account</span></div>
                </div>
            </div>
        )
    }

    getForgotPWForm() {
        return (
            <div className="Signup Login">
                <div className='Login-Heading Login-Heading-Container Signup-Group'><div className='Signup-Heading-Item Signup-Heading-Icon' onClick={() => this.handleBack()}><MdChevronLeft /></div><div className='Login-Heading-Item'>Reset Password</div></div>
                <form onSubmit={this.handleSubmitEmail}>
                    <Form.Group controlId="email" className="form-group form-group-lg Signup-Group">
                        <Form.Label className='Signup-Input'>Email *</Form.Label>
                        <Form.Control
                            autoFocus
                            placeholder="Enter your email address"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            isValid={this.getValidation('email')}
                            className='Login-Field'
                        />
                    </Form.Group>
                    <LoaderButton
                        size="lg"
                        disabled={!this.formIsValid()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text={this.state.isReset ? "Reset Password" : "Forgot Password"}
                        loadingText="Sending Reset Code..."
                        className='Login-Button'
                    />
                </form>
            </div>
        )
    }

    getNewPasswordForm() {
        return (
            <div className='Signup'>
                {
                    this.state.pwUpateIsGood ? <div className="Login Login-Checkmark-Container"><img className='Login-Checkmark' src={Checkmark} alt='All Good' /></div> :
                        <div className="Login">
                            <div className='Login-Heading-Container Login-Heading'><div className='Signup-Heading-Item Signup-Heading-Icon' onClick={() => this.handleBack()}><MdChevronLeft /></div><div className='Signup-Heading Signup-Group'>Create Password</div></div>
                            <div className='Signup-Heading Signup-Hint Signup-Group-2'>{`Please check ${this.state.email} for your verification code (this may take some time).`} <span className='Signup-Link' onClick={() => this.handleResendCode()}> Resend Code</span>.</div>
                            <form onSubmit={this.handleSubmitNewPW}>
                                <Form.Group controlId="code" className="form-group-lg Signup-Group-3">
                                    <Form.Label className='Signup-Input'>Code *</Form.Label>
                                    <Form.Control
                                        autoFocus
                                        type="tel"
                                        placeholder="Code from Email"
                                        value={this.state.code}
                                        onChange={this.handleChange}
                                        isValid={this.getValidation('code')}
                                        isInvalid={!this.getValidation('code')}
                                    />
                                </Form.Group>
                                <Form.Group controlId="pw" className="form-group-lg Signup-Group">
                                    <Form.Label className='Signup-Input'>Password *</Form.Label>
                                    <div className='Signup-Heading-Container'><Form.Control
                                        placeholder="Password"
                                        value={this.state.pw}
                                        onChange={this.handleChange}
                                        type={this.state.showPW ? "text" : "password"}
                                        isValid={this.getValidation('pw')}
                                        isInvalid={!this.getValidation('pw')}
                                    /><div className='Signup-Icon' onClick={() => this.handleShowPW()}>{this.state.showPW ? <FaEye /> : <FaEyeSlash />}</div></div>
                                    <Form.Text className="Signup-Hint">Password must be at least <span className={this.pwIsStrong('length') ? 'Signup-Strong' : 'Signup-Weak'}>8 characters</span>, <span className={this.pwIsStrong('number') ? 'Signup-Strong' : 'Signup-Weak'}>1 number</span>, <span className={this.pwIsStrong('special') ? 'Signup-Strong' : 'Signup-Weak'}>1 special character</span> and <span className={this.pwIsStrong('upper') ? 'Signup-Strong' : 'Signup-Weak'}>1 uppercase letter</span></Form.Text>
                                </Form.Group>
                                <Form.Group controlId="cpw" className="form-group-lg Signup-Group">
                                    <Form.Label className='Signup-Input'>Re-enter Password *</Form.Label>
                                    <div className='Signup-Heading-Container'><Form.Control
                                        placeholder="Re-enter Password"
                                        value={this.state.cpw}
                                        onChange={this.handleChange}
                                        type={this.state.showCPW ? "text" : "password"}
                                        isValid={this.getValidation('cpw')}
                                        isInvalid={!this.getValidation('cpw')}
                                    /><div className='Signup-Icon' onClick={() => this.handleShowCPW()}>{this.state.showCPW ? <FaEye /> : <FaEyeSlash />}</div></div>
                                </Form.Group>
                                <LoaderButton
                                    size="lg"
                                    disabled={!this.formIsValid()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Reset Password"
                                    loadingText="Resetting Password..."
                                    className='Login-Button'
                                />
                            </form>
                        </div>
                }
            </div>
        )
    }

    render() {
        //console.log(this.state)
        return (
            <div className="Signup-Container">
                {this.state.curr === '3' ? this.getNewPasswordForm() : (this.state.curr === '2' ? this.getForgotPWForm() : this.getLoginForm())}
                {
                    this.state.displayMsg && this.getMessage()
                }
            </div>
        )
    }
}

export default withRouter(Login) 