import { Component } from 'react'
import Card from 'react-bootstrap/Card'
import withRouter from '../components/withRouter'
import Logo from '../images/GigLoca_Logo.png'
import './legal.css'

class Legal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: null
    }
  }

  componentDidMount() {
    const { search } = this.props
    const queryParams = new URLSearchParams(search.search)
    this.setState({ page: queryParams.get('page') })
  }

  handleChangeDocument(page) {
    this.setState({ page })
  }

  handleHome() {
    const { navigate } = this.props
    navigate('/')
  }

  getdoc() {
    switch (this.state.page) {
      case 'privacy':
        return <Card>
          <Card.Header><h2>GigLoca Privacy Policy</h2></Card.Header>
          <Card.Body style={{ height: "calc(100vh - 130px)", overflowY: 'auto' }}>
            <h1>Privacy Policy</h1>
            <p>
              Effective 3/21/2024, Gigloca values your privacy and is committed to protecting your personal information.  This Privacy Policy describes how Gigloca and Entertainment Collaborations, LLC. ("Gigloca," "we," "us," or "our") collects, utilizes and shares information about you when you use Gigloca (the "Website", the "Native Application", the "Mobile Application", the "Application", the "App" or the "Service").
            </p>
            <p><b>
              1. Information We Collect
            </b></p>
            <p>
              a.  Information You Provide : When you use our Service, you may provide us with certain personal information such as your name, email address and location.<br />
              b.  Information We Collect Automatically : We may also automatically collect certain information about your use of the Services, such as your location or search activity, for the purpose of tailoring your experience to events and venues within your local entertainment area.
            </p>
            <p><b>
              2. How We Use Your Information
            </b></p>
            <p>
              We may use the information we collect for various purposes, including:
            </p>
            <p>
              a. To provide, secure and maintain our Services.<br />
              b. To improve our Services and develop new features that better support our users.<br />
              c. To communicate with you, including responding to your inquiries and providing customer support.<br />
              d. To analyze usage trends and preferences so that we can support local partners that shape the event options in your area.<br />
              e. To detect, prevent, and address technical issues and security vulnerabilities.
            </p>
            <p><b>
              3. Information Sharing
            </b></p>
            <p>
              While Gigloca does not intend to share your personal information for marketing, we may share your information with third parties in the following circumstances:
            </p><p>
              a. With your consent to use our services.<br />
              b. With service providers who help us perform our Services.<br />
              c. To comply with legal obligations or to protect our legal rights.<br />
              d. In connection with a merger, acquisition, or sale of assets.
            </p>
            <p><b>
              4. Cookies and Similar Technologies
            </b></p>
            <p>
              We do <u>not</u> use cookies or similar technologies to collect information about your use of the Services; however, if you disable cookies, it may impact your experience because it limits our ability to authenticate you and protect your privacy.
            </p>
            <p><b>
              5. Data Security
            </b></p>
            <p>
              We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, plese note that no method of transmission over the Internet or electronic storage is 100% secure.
            </p>
            <p><b>
              6. Children's Privacy
            </b></p>
            <p>
              Our Service is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you believe we have collected personal information from a child under 13, please <a href="https://gigloca.com/contactus">contact us</a> immediately.
            </p>
            <p><b>
              7. Your Choices
            </b></p>
            <p>
              You have certain choices regarding the information we collect and how it is used. You can opt out of receiving promotional emails from us by setting your preferences in the application at <a href="https://gigloca.com/pref">Preferences (https://gigloca.com/pref)</a>.
            </p>
            <p><b>
              8. Changes to this Privacy Policy
            </b></p>
            <p>
              We may update this Privacy Policy from time to time as a result of evolving regulations and environmental circumstances. We will notify you of any changes by posting the new Privacy Policy at <a href="https://gigloca.com/legal?page=privacy">Privacy (https://gigloca.com/legal?page=privacy)</a>.
            </p>
            <p><b>
              9. Contact Us
            </b></p>
            <p>
              If you have any questions about this Privacy Policy, please contact us at <a href="https://gigloca.com/contactus">https://gigloca.com/contactus</a>.
            </p>
            <p>
              By using Gigloca, you consent to the terms of this Privacy Policy.
            </p><p>
              Thank you for using Gigloca!
            </p>
          </Card.Body>
          <Card.Footer>
            <Card.Link className='Legal-Link' onClick={() => this.handleChangeDocument('tou')}>Terms of Use</Card.Link>
            <Card.Link className='Legal-Link' onClick={() => this.handleChangeDocument('data')}>Data Policy</Card.Link>
            <span style={{ marginLeft: '20px' }} onClick={() => this.handleHome()}><img className='Legal-Img' src={Logo} alt='Home' /></span>
          </Card.Footer>
        </Card>
      case 'tou':
        return <Card>
          <Card.Header><h2>GigLoca Terms of Use</h2></Card.Header>
          <Card.Body>
            <h1>Terms of Use</h1>
            <p>Effective 3/21/2024, Gigloca is committed to maintaining appropriate use of our services.  This policy outlines how users are permitted to use our services.</p>
            <p>
              Welcome to Gigloca and <a href="https://gigloca.com">Gigloca.com</a>! These Terms of Use ("Terms") govern your use of Gigloca (the "Website", the "Native Application", the "Mobile Application", the "Application", the "App" or the "Service") provided by Entertainment Collaborations, LLC. ("Gigloca," "we," "us," or "our"). By accessing or using our Website, you agree to comply with these Terms. If you do not agree with these Terms, you may not access or use the Service.
            </p>
            <p><b>
              1. Acceptance of Terms
            </b></p>
            <p>
              By accessing or using Gigloca, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Website or Service.
            </p>
            <p><b>
              2. Use of the Service
            </b></p>
            <p>
              You may use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:
            </p>
            <p>
              a. In any way that violates any applicable law or regulation.<br />
              b. To engage in any activity that infringes on the rights of others.<br />
              c. To transmit any material that is defamatory, obscene, or otherwise objectionable.<br />
            </p>
            <p><b>
              3. Privacy Policy
            </b></p>
            <p>
              Your privacy is important to us. Our <a href="https://gigloca.com/legal?page=privacy">Privacy Policy</a> explains how we collect, use, and disclose information about you. By using the Service, you consent to our Privacy Policy.
            </p>
            <p><b>
              4. User Accounts
            </b></p>
            <p>
              To access certain features of the Service, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur within your account.
            </p><p>
              Gigloca reserves the right to terminate or block any account for any reason and without notice, including but not limited to violations of these Terms or suspected fraudulent, abusive, or illegal activity.
            </p>
            <p><b>
              5. Intellectual Property
            </b></p>
            <p>
              All content and materials available on Gigloca, including but not limited to text, graphics, logos, images, and software, are the property of Gigloca and Entertainment Collaborations, LLC., and are protected by copyright, trademark, and other intellectual property laws.
            </p>
            <p><b>
              6. Limitation of Liability
            </b></p>
            <p>
              To the fullest extent permitted by law, Gigloca and Entertainment Collaborations, LLC. shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of the Service.
            </p>
            <p><b>
              7. Modifications to Terms
            </b></p>
            <p>
              Gigloca reserves the right to modify or update these Terms at any time without prior notice. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.  Terms of use may be accessed at <a href="https://gigloca.com/legal?page=tou">https://gigloca.com/legal?page=tou</a>.
            </p>
            <p><b>
              8. Governing Law
            </b></p>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law principles.
            </p>
            <p><b>
              9. Contact Us
            </b></p>
            <p>
              If you have any questions about these Terms, please contact us at <a href="https://gigloca.com/contactus">https://gigloca.com/contactus</a>.
            </p><p>
              By using Gigloca, you agree to these Terms of Use.
            </p><p>
              Thank you for using Gigloca!
            </p>
          </Card.Body>
          <Card.Footer>
            <Card.Link className='Legal-Link' onClick={() => this.handleChangeDocument('privacy')}>Privacy Policy</Card.Link>
            <Card.Link className='Legal-Link' onClick={() => this.handleChangeDocument('data')}>Data Policy</Card.Link>
            <span style={{ marginLeft: '20px' }} onClick={() => this.handleHome()}><img className='Legal-Img' src={Logo} alt='Home' /></span>
          </Card.Footer>
        </Card>
      case 'data':
        return <Card>
          <Card.Header><h2>GigLoca Data Policy</h2></Card.Header>
          <Card.Body>
            <h1>Data Policy</h1>
            <p>
              Effective 3/21/2024, Gigloca is committed to protecting your privacy and ensuring the security of your personal information. This Data Policy outlines how we collect, use, and protect the data you provide to us when you use Gigloca (the "Website", the "Native Application", the "Mobile Application", the "Application", the "App" or the "Service").
            </p>
            <p><b>
              1. Information We Collect
            </b></p>
            <p>
              When you use Gigloca, we may collect the following types of information:
            </p>
            <p>
              - Personal Information: When you create an account or interact with our Services, we may collect personal information such as your name, email address, date of birth, and location.<br />
              - Event Information: If you create or participate in events through our Services, we may collect information about those events, such as the location, date, time, and description.<br />
              - Usage Information: We may collect information about how you use the Website or Services, including your search queries and interactions with the Service.<br />
            </p >
            <p><b>
              2. How We Use Your Information
            </b></p>
            <p>
              We may use the information we collect for the following purposes:
            </p>
            <p>
              - To provide and improve our Service.<br />
              - To personalize your experience and customize the content and features of the Services.<br />
              - To communicate with you about your account and our Services.<br />
              - To analyze usage trends and gather demographic information about our users and their interests.<br />
              - To offer improvement opportunities to venues, contributors and events.< br />
              - To prevent fraud and ensure the security of our Services.
            </p >
            <p><b>
              3. Data Sharing
            </b></p>
            <p>
              We do not share your personal information with third parties for marketing purposes. However, we may share your information with third-party service providers who assist us in operating our Service, conducting business, or servicing you, provided that those parties agree to keep this information confidential.
            </p>
            <p>
              We may also disclose your information if required by law or in response to a valid legal request, such as a court order or subpoena.
            </p>
            <p><b>
              4. Data Security
            </b></p>
            <p>
              We take reasonable measures to protect the security of your personal information and prevent unauthorized access, disclosure, or alteration.  We encrypt all data at rest (encrypted database) and in flight (SSL). However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.
            </p>
            <p><b>
              5. Data Retention
            </b></p>
            <p>
              We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Data Policy, unless a longer retention period is required or permitted by law.
            </p>
            <p><b>
              6. Your Rights
            </b></p>
            <p>
              You have the right to access, update, and delete your personal information. You may also opt-out of receiving marketing communications from us at any time by updating your <a href="https://gigloca.com/pref">Preferences</a>.
            </p>
            <p><b>
              7. Children's Privacy
            </b></p>
            <p>
              Gigloca is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under 13. If you believe that we have inadvertently collected information from a child under 13, please contact us immediately at < a href="https://gigloca.com/contactus" > https://gigloca.com/contactus</a>.
            </p>
            <p><b>
              8. Changes to this Policy
            </b></p>
            <p>
              We may update this Data Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the updated policy here.
            </p>
            <p><b>
              9. Contact Us
            </b></p>
            <p>
              If you have any questions about this Data Policy or our privacy practices, please contact us at <a href="https://gigloca.com/contactus">https://gigloca.com/contactus</a>.
            </p>
            <p>
              By using Gigloca, you consent to the collection and use of your information as described in this Data Policy.
            </p><p>
              Thank you for using Gigloca!
            </p>
          </Card.Body >
          <Card.Footer>
            <Card.Link className='Legal-Link' onClick={() => this.handleChangeDocument('privacy')}>Privacy Policy</Card.Link>
            <Card.Link className='Legal-Link' onClick={() => this.handleChangeDocument('tou')}>Terms of Use</Card.Link>
            <span style={{ marginLeft: '20px' }} onClick={() => this.handleHome()}><img className='Legal-Img' src={Logo} alt='Home' /></span>
          </Card.Footer>
        </Card >
      default:
        return <Card>
          <Card.Header><h2>Welcome to GigLoca.</h2></Card.Header>
          <Card.Body>Select a document.</Card.Body>
          <Card.Footer>
            <Card.Link className='Legal-Link' onClick={() => this.handleChangeDocument('privacy')}>Privacy Policy</Card.Link>
            <Card.Link className='Legal-Link' onClick={() => this.handleChangeDocument('tou')}>Terms of Use</Card.Link>
            <Card.Link className='Legal-Link' onClick={() => this.handleChangeDocument('data')}>Data Policy</Card.Link>
            <span style={{ marginLeft: '20px' }} onClick={() => this.handleHome()}><img className='Legal-Img' src={Logo} alt='Home' /></span>
          </Card.Footer>
        </Card>
    }
  }

  render() {
    return (
      <div className="Legal-Container">
        {
          this.getdoc()
        }
      </div>
    )
  }
}

export default withRouter(Legal)