import React from 'react'
import { TwitterShareButton, XIcon } from 'react-share'

const ShareX = (props) => {
    //console.log(props)
    return <div>
        <TwitterShareButton url={props.url} quote={props.quote} hashtags={props.hashtag} title={props.title}>
            <XIcon size={props.size} round />
        </TwitterShareButton>
    </div>
}

export default ShareX