import { Component } from 'react'
import Form from 'react-bootstrap/Form'
import LoaderButton from './loaderButton'
import SmallCard from './smallCard'
import { BsHouseDoor, BsMap, BsListStars } from 'react-icons/bs'
import { RxDashboard } from 'react-icons/rx'
import { TbCalendarPlus, TbHexagonLetterG } from 'react-icons/tb'
import { MdOutlineSearch, MdLocationOff, MdLocationOn, MdAddBusiness, MdOutlineBuild } from 'react-icons/md'
import { FaArrowLeft } from 'react-icons/fa'
import { LuCalendarOff, LuCalendarSearch } from 'react-icons/lu'
import { SiCodereview } from 'react-icons/si'
import { GiGearHammer } from 'react-icons/gi'
import { PiUserCirclePlusBold } from 'react-icons/pi'
import { BiReset, BiSolidUserAccount } from 'react-icons/bi'
import './bottomMenu.css'

class BottomMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            active: false
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ active: true }), 800)
    }

    handleLeftSelect() {
        this.props.onLeft()
    }

    handleReset() {
        this.props.onReset()
    }

    handleGiglit() {
        this.props.onGiglit()
    }

    handleRightSelect() {
        if (this.state.active) {            
            this.props.onRight()            
        }
    }

    handleFind() {
        this.props.onFind()
    }

    handleCatSelect(event) {
        this.props.onCat(event)
    }

    handleByDist() {
        this.props.onByDist()
    }

    handleByDate() {
        this.props.onByDate()
    }

    handleCardSelected(nid) {
        this.props.onCardSelected(nid)
    }

    formIsValid() {
        return this.props.node.length > 0
    }

    getLeft() {
        if (this.props.menu && this.props.menu.left) {
            switch (this.props.menu.left) {
                case 'home':
                    return <div className='BottomMenu-Item' onClick={() => this.handleLeftSelect()}>
                        <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><BsHouseDoor /></div>
                        <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>Home</div>
                    </div>
                    case 'account':
                        return <div className='BottomMenu-Item' onClick={() => this.handleLeftSelect()}>
                            <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><BiSolidUserAccount /></div>
                            <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>Account</div>
                        </div>
                case 'find':
                    return <div className='BottomMenu-Item' onClick={() => this.handleLeftSelect()}>
                        <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><MdOutlineSearch /></div>
                        <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>Find</div>
                    </div>
                case 'dashboard':
                    return <div className='BottomMenu-Item' onClick={() => this.handleLeftSelect()}>
                        <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><RxDashboard /></div>
                        <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>My List</div>
                    </div>
                default:
                    return <div className='BottomMenu-Item'></div>
            }
        } else return <div className='BottomMenu-Item'></div>
    }

    getGiglit() {
        if (this.props.menu && this.props.menu.mode) {
            switch (this.props.menu.mode) {
                case 'fan':
                    return <div className='BottomMenu-Item' onClick={() => this.handleGiglit()}>
                        <div className={`BottomMenu-Giglit BottomMenu-Giglit-Red`}><div style={{ paddingTop: '3px' }} className='BottomMenu-Giglit-Icon'><TbHexagonLetterG /></div></div>
                    </div>
                case 'list':
                    return <div className='BottomMenu-Item' onClick={() => this.handleGiglit()}>
                        <div className='BottomMenu-Giglit BottomMenu-Giglit-Blue'><div style={{ paddingTop: '3px' }} className='BottomMenu-Giglit-Icon'><TbHexagonLetterG /></div></div>
                    </div>
                case 'Venue':
                    return <div className='BottomMenu-Item' onClick={() => this.handleGiglit()}>
                        <div className='BottomMenu-Giglit BottomMenu-Giglit-Blue'><div className='BottomMenu-Giglit-Icon'>{this.props.menu.action === 'addNode' ? <MdAddBusiness /> : <TbCalendarPlus />}</div></div>
                    </div>
                case 'Contributor':
                    return <div className='BottomMenu-Item' onClick={() => this.handleGiglit()}>
                        <div className='BottomMenu-Giglit BottomMenu-Giglit-Red'><div style={{ paddingTop: '3px' }} className='BottomMenu-Giglit-Icon'>{this.props.menu.action === 'findGigs' ? <GiGearHammer /> : <PiUserCirclePlusBold />}</div></div>
                    </div>
                case 'event':
                    return null
                default:
                    return <div className='BottomMenu-Item'></div>
            }
        } else return <div className='BottomMenu-Item'></div>
    }

    getRight() {
        if (this.props.menu && this.props.menu.right) {
            switch (this.props.menu.right) {
                case 'map':
                    return <div className='BottomMenu-Item' onClick={() => this.handleRightSelect()}>
                        <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><BsMap /></div>
                        <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>Map</div>
                    </div>
                case 'list':
                    return <div className='BottomMenu-Item' onClick={() => this.handleRightSelect()}>
                        <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><BsListStars /></div>
                        <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>List</div>
                    </div>
                case 'edit':
                    return <div className='BottomMenu-Item' onClick={() => this.handleRightSelect()}>
                        <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><MdOutlineBuild /></div>
                        <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>Edit</div>
                    </div>
                case 'view':
                    return <div className='BottomMenu-Item' onClick={() => this.handleRightSelect()}>
                        <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><SiCodereview /></div>
                        <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>View</div>
                    </div>
                default:
                    return <div className='BottomMenu-Item'></div>
            }
        } else return <div className='BottomMenu-Item'></div>
    }

    getFind() {
        return <div className='Find-Container'>
            <div className='Find-Flex-Spread'>
                <div style={{ marginLeft: '10px', fontSize: '14pt', color: '#fff', marginTop: '5px', cursor: 'pointer' }} onClick={() => this.handleLeftSelect()}><FaArrowLeft /></div>
                <div style={{ marginRight: '10px', fontSize: '14pt', color: '#fff', marginTop: '5px', cursor: 'pointer' }} onClick={() => this.handleReset()}><BiReset /></div>
            </div>
            <div className='Find-Top'>
                <div className='Find-Row'>
                    <Form.Select style={{ color: '#190753', borderRadius: '5px' }} name='node' value={this.props.node} onChange={this.props.onFindChanged}>
                        <option value=''>What are we looking for?</option>
                        {
                            this.props.options && this.props.options.map((opt, oidx) => {
                                return <option key={`opt-${oidx}`} value={opt.ptype}>{opt.ptype}</option>
                            })
                        }
                    </Form.Select>
                </div>
                <div className='Find-Row'>
                    <Form.Group>
                        <Form.Label>Should we narrow it down?</Form.Label>
                        <div className='Find-Row-Flex'>
                            {
                                this.props.options && this.props.node && this.props.options[this.props.options.findIndex(o => o.ptype === this.props.node)].categories.map((cat, cidx) => {
                                    return <Form.Check key={`cat-${cidx}`} type='checkbox' value={Object.keys(cat)[0]} label={Object.keys(cat)[0]} checked={this.props.cat && this.props.cat.indexOf(Object.keys(cat)[0]) > -1} onChange={(event) => this.handleCatSelect(event)} />
                                })
                            }
                        </div>
                    </Form.Group>
                </div>
                <div className='Find-Row'>
                    <Form.Group className="form-group form-group-lg">
                        <Form.Label>Do we know part of the name?</Form.Label>
                        <Form.Control
                            placeholder="Name - full or partial"
                            type="text"
                            value={this.props.nodeName}
                            onChange={this.props.onFindNameChanged}
                            isValid={this.props.nodeName.length > 3}
                            className='Find-Field'
                        />
                    </Form.Group>
                </div>
                <div className='Find-Row-Flex'>
                    <Form.Check type='checkbox' value='byDist' label='Filter by location' checked={this.props.byDist} onChange={() => this.handleByDist()} />
                    <div style={{ marginLeft: '5px' }}>{this.props.byDist ? <MdLocationOn /> : <MdLocationOff />}</div>
                </div><div className='Find-Row-Flex'>
                    <Form.Check type='checkbox' value='byDate' label='Filter by date' checked={this.props.byDate} onChange={() => this.handleByDate()} />
                    <div style={{ marginLeft: '5px' }}>{this.props.byDate ? <LuCalendarSearch /> : <LuCalendarOff />}</div>
                </div>
                <LoaderButton

                    disabled={!this.formIsValid()}
                    type="submit"
                    isLoading={this.props.isFinding}
                    text="Find"
                    loadingText="Finding Matches..."
                    className='Find-Button'
                    onClick={() => this.handleFind()}
                />
            </div>
            <div className='Find-Cards-Container'>
                <div className='Find-Cards'>{this.props.findCards && this.props.findCards.map((c, cidx) => {
                    return <div key={`card-${cidx}`} style={{ marginBottom: '5px' }} className={this.props.nodes && this.props.nodes.indexOf(c.id) > -1 ? 'Find-Card-Selected' : ''} onClick={() => this.handleCardSelected(c.id)}>
                        <SmallCard data={c} type={c.nodetype === 'Venue' ? 'venue' : 'contributor'} />
                    </div>
                })}</div>

            </div>
        </div>
    }

    render() {
        //console.log(this.props)
        return <div className='BottomMenu-Container'>
            {this.getLeft()}
            {this.getGiglit()}
            {this.getRight()}
            {this.props.displayFind && this.getFind()}
        </div>
    }
}

export default BottomMenu