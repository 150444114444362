import { useNavigate, useLocation, useParams } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
    const navigate = useNavigate()
    const search = useLocation()
    const path = useParams()

    // other hooks

    return (
        <WrappedComponent
            {...props}
            {...{ navigate, search, path }}
        />
    );
};

export default withRouter;