import { Component, createRef } from 'react'
import withRouter from '../components/withRouter'
import { GoogleMap, LoadScript, InfoWindow, Marker } from '@react-google-maps/api'
import TopMenu from '../components/topMenu'
import BottomMenu from '../components/bottomMenu'
import MiniWide from '../components/miniWide'
import Card from '../components/card'
import SmallCard from '../components/smallCard'
import { FaRecordVinyl } from 'react-icons/fa'
import { FaArrowDownUpAcrossLine } from 'react-icons/fa6'
import { GiMicrophone, GiGuitar } from 'react-icons/gi'
import { CiBeerMugFull } from 'react-icons/ci'
import { MdDining, MdOutlineQuestionAnswer } from 'react-icons/md'
import { BiReset } from 'react-icons/bi'
import { BsFire } from 'react-icons/bs'
import { TbFilterSearch } from 'react-icons/tb'
import { API } from 'aws-amplify'
import moment from 'moment'
import Beer from '../images/beer.png'
import Food from '../images/food.png'
import Mic from '../images/mic-p.png'
import Crowd from '../images/crowd-p.png'
import Venue from '../images/venue.png'
import Comedy from '../images/comedy-p.png'
import ComedyClub from '../images/comedy.png'
import Music from '../images/music-p.png'
import Church from '../images/chruch.png'
import Club from '../images/club.png'
import DJ from '../images/dj-p.png'
import Party from '../images/party-watch-party.png'
import Tasting from '../images/tasting.png'
import Crafts from '../images/arts-crafts.png'
import Booth from '../images/booth.png'
import Bounce from '../images/bounce_house.png'
import Paint from '../images/paint.png'
import Performer from '../images/performer.png'
import './fan.css'

class Fan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            lastSearch: { dist: 25 },
            loc: '',
            lastLoc: '',
            locIsGood: false,
            dist: 25,
            center: null,
            options: null,
            month: moment().date(1).format('YYYY-MM-DD'),
            days: [moment().date()],
            start: moment().format('YYYY-MM-DD'),
            end: moment().add(1, 'day').format('YYYY-MM-DD'),
            pref: null,
            topic: null,
            searchChanged: false,
            mapsize: 'half',
            view: 'map',
            menu: {
                left: 'account',
                right: 'list',
                giglit: 'red',
                mode: 'fan'
            },
            filteredData: null,
            event: null,
            venue: null,
            zoom: 10,
            displaySettings: false,
            displaySearch: false,
            next: null
        }
        this.handleLocationCheck = this.handleLocationCheck.bind(this)
        this.handleLocChanged = this.handleLocChanged.bind(this)
        this.handleDistChanged = this.handleDistChanged.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleDayChanged = this.handleDayChanged.bind(this)
        this.handleMonthChanged = this.handleMonthChanged.bind(this)
        this.handleMonthSelected = this.handleMonthSelected.bind(this)
        this.handleOptionSelect = this.handleOptionSelect.bind(this)
        this.handleGetMyLoc = this.handleGetMyLoc.bind(this)
        this.handleFollow = this.handleFollow.bind(this)
        this.handleInterest = this.handleInterest.bind(this)
        this.handleAttending = this.handleAttending.bind(this)
        this.handleLeftMenu = this.handleLeftMenu.bind(this)
        this.handleRightMenu = this.handleRightMenu.bind(this)
        this.handleGiglit = this.handleGiglit.bind(this)
        this.handleGoToCard = this.handleGoToCard.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleDisplaySearch = this.handleDisplaySearch.bind(this)
        this.handleResetOptions = this.handleResetOptions.bind(this)
    }

    FanCards = createRef()

    componentDidMount() {
        const { search } = this.props
        const queryParams = new URLSearchParams(search.search)
        const refresh = queryParams.get('refresh') || false
        if (!this.props.data || refresh) {
            if (this.props.myloc) {
                this.setState({ center: this.props.myLoc, dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist })
                this.getData(this.props.myLoc, this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist)
            } else {
                const gl = navigator.geolocation
                gl.getCurrentPosition(position => {
                    this.props.onMyLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                    this.setState({ center: { lat: position.coords.latitude, lng: position.coords.longitude }, isMounting: false, dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist })
                    this.getData({ lat: position.coords.latitude, lng: position.coords.longitude }, this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist)
                }, err => {
                    if (this.props.user && this.props.user.defaultLoc) {
                        this.props.onMyLoc(this.props.user.defaultLoc)
                        this.setState({ center: this.props.user.defaultLoc, dist: this.props.user.dist || this.state.dist })
                        this.getData(this.props.user.defaultLoc, this.props.user.dist || this.state.dist)
                    } else this.setState({ isLoading: false })
                })
            }
        } else {
            if (this.props.filter) {
                this.setState({
                    center: this.props.myLoc,
                    loc: this.props.filter.loc || '',
                    dist: this.props.filter.dist || (this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist),
                    start: this.props.filter.start || null,
                    end: this.props.filter.end || null,
                    days: this.props.filter.days || [],
                    pref: this.props.filter.pref || null
                })
            } else {
                this.setState({ center: this.props.myLoc, dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist })
            }
            this.handleFilter(null, this.props.filter ? (this.props.filter.pref || null) : this.state.pref)
        }
    }

    handleDisplaySettings() {
        this.setState({ displaySearch: false, displaySettings: !this.state.displaySettings })
    }

    handleDisplaySearch() {
        if (this.state.displaySearch) {
            this.handleSearch()
            this.setState({ displaySettings: false, displaySearch: false, topic: null, menu: { ...this.state.menu, mode: 'fan' } })
        } else {
            if (!this.state.options && this.props.isAuthenticated) {
                API.get('lt', 'options?prop=node')
                    .then(resp => this.setState({ options: resp.data || [] }))
                    .catch(err => {
                        console.log(err)
                        if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                    })
            }
            this.setState({ displaySettings: false, displaySearch: true, topic: null, menu: { ...this.state.menu, mode: null } })
        }
    }

    handleGetMyLoc() {
        const gl = navigator.geolocation
        gl.getCurrentPosition(position => {
            API.get('lt', `geo?coord=${position.coords.latitude},${position.coords.longitude}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    } else {
                        this.setState({ locIsGood: false, loc: 'Not Found' })
                    }
                })
        }, err => {
            console.log(err)
            this.setState({ locIsGood: false, loc: 'Not Found' })
        })
    }

    handleLocationCheck() {
        if (this.state.loc && this.state.loc !== this.state.lastLoc) {
            API.get('lt', `geo?address=${this.state.loc.replace(/#/g, '%23')}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    }
                })
                .catch(e => {
                    this.setState({ loc: '', locIsGood: false })
                })
        }
    }

    handleDistChanged(event) {
        let dist = event.target.value === "0" ? "1" : (event.target.value)
        this.setState({ dist })
    }

    handleLocChanged(event) {
        this.setState({ locIsGood: false, loc: event.target.value.replace(/[^a-zA-Z0-9-, ]/g, "") })
    }

    handleSearch() {
        let doIt = false
        if (this.state.lastSearch) {
            if (this.state.lastSearch.dist && this.state.lastSearch.dist !== this.state.dist) {
                doIt = true
            } else {
                if (this.state.searchChanged) {
                    doIt = true
                } else if (!this.state.lastSearch.start ||
                    !this.state.lastSearch.end ||
                    !this.state.start ||
                    !this.state.end ||
                    moment(this.state.start).isBefore(moment(this.state.lastSearch.start)) ||
                    moment(this.state.end).isAfter(moment(this.state.lastSearch.end))) {
                    doIt = true
                }
            }
            if (doIt) {
                this.props.setFilter({
                    loc: this.state.loc,
                    dist: this.state.dist,
                    start: this.state.start,
                    end: this.state.end,
                    days: this.state.days,
                    pref: this.state.pref
                })
                this.getData()
            } else this.handleFilter()
        } else this.getData()
    }

    handleOptionSelect(node, cat) {
        if (node === 'amenities') {
            let amenities = this.state.pref && this.state.pref.amenities ? this.state.pref.amenities : []
            let idx = amenities.indexOf(cat)
            if (idx > -1) {
                amenities.splice(idx, 1)
            } else amenities.push(cat)
            this.setState({ pref: { ...this.state.pref, amenities } })
        } else {
            if (this.state.pref && this.state.pref[node]) {
                let cats = [...this.state.pref[node]]
                let idx = cats.indexOf(cat)
                if (idx === -1) {
                    cats.push(cat)
                } else cats.splice(idx, 1)
                this.setState({ pref: { ...this.state.pref, [node]: cats } })
            } else {
                if (this.state.pref && this.state.pref.amenities) {
                    this.setState({ pref: { amenities: this.state.pref.amenities, [node]: [cat] } })
                } else this.setState({ pref: { [node]: [cat] } })
            }
        }
    }

    handleResetOptions() {
        this.setState({ pref: null })
    }

    handleFilter(d, p) {
        const pref = p || this.state.pref
        const amenities = pref ? pref['amenities'] : null
        const idx = pref ? Object.keys(pref).findIndex(p => p !== 'amenities') : -1
        const node = idx > -1 ? Object.keys(pref)[idx] : null
        const categories = idx > -1 ? Object.values(pref)[idx] : null
        let end = this.props.filter ? this.props.filter.end : this.state.end
        let start = this.props.filter ? this.props.filter.start : this.state.start
        let dist = this.props.filter && this.props.filter.dist ? this.props.filter.dist : this.state.dist
        if (d || this.props.data) {
            this.setState({ isLoading: true })
            let data = d || JSON.parse(JSON.stringify(this.props.data))
            let filteredData = { events: [], venues: [] }
            data.filter(d => d.nodetype === 'Venue').forEach(v => {
                let vkeep = null
                if (v.events) {
                    if (!pref || !pref['Venue']) {
                        v.events && v.events.filter(e => (!end || moment(e.startTime).isBefore(moment(end))) &&
                            (!start || moment(e.stopTime).isAfter(moment(start)))).sort((a, b) => {
                                if (moment(a.startTime).isBefore(moment(b.startTime))) return -1
                                if (moment(a.startTime).isAfter(moment(b.startTime))) return 1
                                return 0
                            }).forEach(e => {
                                let keep = true
                                if (amenities) {
                                    amenities.forEach(a => {
                                        if (e.amenities.indexOf(a) === -1) keep = false
                                    })
                                }
                                if (keep) {
                                    if (node) {
                                        keep = e.searchSlots && e.searchSlots.filter(s => s.node === node && pref[node].indexOf(s.category) > -1).length > 0
                                        if (!keep && (!e.searchSlots || e.searchSlots.length === 0) && categories && e.summary && categories.indexOf(e.summary) > -1) keep = true
                                    }
                                }
                                if (keep) {
                                    filteredData.events.push(e)
                                    if (!vkeep) {
                                        vkeep = [v.id]
                                    } else if (vkeep.indexOf(v.id) === -1) vkeep.push(v.id)
                                }
                            })
                    }
                }
                if (!node || (node === 'Venue' && pref[node].indexOf(v.category) > -1)) {
                    let keep = true
                    if (amenities) {
                        amenities.forEach(a => {
                            if (v.amenities.indexOf(a) === -1) keep = false
                        })
                    }
                    if (keep) {
                        filteredData.venues.push(v)
                    }
                } else if (node && node !== 'Venue') {
                    if (vkeep) data.filter(d => d.nodetype === 'Venue').forEach(v => {
                        if (vkeep.indexOf(v.id) > -1) {
                            filteredData.venues.push(v)
                        }
                    })
                } else {
                    //console.log('keep 3', v)
                    //filteredData.venues.push(v)
                }
            })
            filteredData.venues.sort((a, b) => {
                if ((a.distance || dist) > (b.distance || dist)) return 1
                if ((a.distance || dist) < (b.distance || dist)) return -1
                return 0
            })
            filteredData.events.sort((a, b) => {
                if ((a.distance || dist) > (b.distance || dist)) return 1
                if ((a.distance || dist) < (b.distance || dist)) return -1
                return 0
            })
            this.setState({ filteredData, isLoading: false })
        }
    }

    handleHotTopic() {
        let filteredData = { events: [], venues: [] }
        if (this.props.data) {
            this.setState({ isLoading: true })
            this.props.data.filter(d => d.nodetype === 'Venue').forEach(v => {
                if (v.events && v.events.length > 0) {
                    v.events.forEach(e => {
                        if (e.interested || e.attending) {
                            if (filteredData.events[0]) {
                                if (((e.interested || 0) + (e.attending || 0)) > ((filteredData.events[0].interested || 0) + (filteredData.events[0].attending || 0))) {
                                    filteredData = { events: [v.events[0]], venues: [v] }
                                }
                            } else {
                                filteredData = { events: [v.events[0]], venues: [v] }
                            }
                        }
                    })
                }
            })
        }
        this.setState({ filteredData, isLoading: false })
    }

    handleHotTopics(topic) {
        let pref = null
        switch (topic) {
            case 'restaurant':
                pref = { 'Venue': ['Restaurant'], amenities: null }
                break
            case 'bar':
                pref = { 'Venue': ['Restaurant', 'Bar', 'Nightclub'], amenities: ['Beer'] }
                break
            case 'dj':
                pref = { 'Entertainment': ['DJ'], amenities: null }
                break
            case 'karaoke':
                pref = { 'Entertainment': ['Karaoke'], amenities: null }
                break
            case 'music':
                pref = { 'Entertainment': ['Acoustic', 'Band', 'Solo', 'Tribute Band', 'Group', 'Duet'], amenities: null }
                break
            case 'trivia':
                pref = { 'Entertainment': ['Trivia'], amenities: null }
                break
            default:
                pref = {}
        }
        this.setState({ pref, topic, event: null, venue: null, menu: { ...this.state.menu, right: 'list' } })
        this.props.setFilter({
            loc: this.state.loc,
            dist: this.state.dist,
            start: this.state.start,
            end: this.state.end,
            days: this.state.days,
            pref
        })
        if (topic === 'fire') {
            this.handleHotTopic()
        } else { this.handleFilter(null, pref) }
    }

    handleModeChange(mode) {
        this.setState({ displaySettings: false })
        if (mode === 'contactus') {
            this.handleNavigate('contactus')
        } else {
            if (mode !== 'fan') {
                this.props.setMode(mode)
                this.handleNavigate(`my/${mode}s`)
            }
        }
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications')
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile')
    }

    handleMonthChanged(dir) {
        let month = moment(this.state.month)
        let days = []
        if (dir === 'back') {
            month.subtract(1, 'month')
        } else if (dir === 'next') {
            month.add(1, 'month')
        }
        let start = this.state.start ? moment(this.state.start) : null
        let end = this.state.end ? moment(this.state.end) : null
        if (start && end) {
            if (end.isBefore(month) || start.isAfter(moment(month).add(1, 'month'))) {
                days = []
            } else if (start.isBefore(month)) {
                if (end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = 1; d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else if (end.isBetween(month, moment(month).add(1, 'month'))) {
                    for (let d = 1; d < end.date(); d++) {
                        days.push(d)
                    }
                }
            } else if (start.isSame(month) || start.isBetween(month, moment(month).add(1, 'month'))) {
                if (end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = start.date(); d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else {
                    for (let d = start.date(); d < end.date(); d++) {
                        days.push(d)
                    }
                }
            }
        } else console.log('no days')
        this.setState({ days, month: month.format('YYYY-MM-DD'), start: start ? start.format('YYYY-MM-DD') : null, end: end ? end.format('YYYY-MM-DD') : null })
    }

    handleMonthSelected() {
        this.setState({ days: [], start: null, end: null })
    }

    handleDayChanged(day) {
        if (this.state.start) {
            let month = moment(this.state.month)
            let sel = moment(this.state.month).date(day)
            let start = moment(this.state.start)
            let end = moment(this.state.end)
            let days = []
            if (sel.isBefore(end)) {
                start = sel
            } else {
                end = moment(sel).add(1, 'day')
            }
            if (start.isBefore(month)) {
                if (end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = 1; d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else if (end.isBetween(month, moment(month).add(1, 'month'))) {
                    for (let d = 1; d < end.date(); d++) {
                        days.push(d)
                    }
                }
            } else if (start.isSame(month) || start.isBetween(month, moment(month).add(1, 'month'))) {
                if (end.isSame(moment(month).add(1, 'month')) || end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = start.date(); d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else {
                    for (let d = start.date(); d < end.date(); d++) {
                        days.push(d)
                    }
                }
            }
            this.setState({ days, start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD'), searchChanged: true })
        } else this.setState({ start: moment(this.state.month).date(day).format('YYYY-MM-DD'), end: moment(this.state.month).date(day).add(1, 'day').format('YYYY-MM-DD'), days: [day], searchChanged: true })
    }

    handleSwipeClick() {
        this.setState({ mapsize: this.state.mapsize === 'full' ? 'half' : 'full', event: null, venue: null })
    }

    handleEventSelected(eid) {
        if (eid) {
            const headers = this.props.user && this.props.user.active ? null : { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } }
            API.get('lt', `${this.props.user && this.props.user.active ? 'fan' : ''}event/${eid}${this.props.myLoc ? `?lat=${this.props.myLoc.lat}&lng=${this.props.myLoc.lng}` : ''}`, headers)
                .then(resp => {
                    this.setState({ event: resp.data, venue: null, mapsize: 'half', menu: { ...this.state.menu, right: 'map' }, center: resp.data.address && resp.data.address.coord ? resp.data.address.coord : this.state.center, zoom: resp.data.address && resp.data.address.coord ? 15 : 10 })
                    this.scrollToRef(this.FanCards)
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        }
    }

    handleAttending(id) {
        if (this.state.event) {
            if (this.props.isAuthenticated) {
                API.put('lt', `interest/${id}`, { body: { status: 'attending', local: moment().format('YYYY-MM-DD HH:mm') } })
                    .then(resp => {
                        let idx = this.props.data.findIndex(d => d.id === this.state.event.id)
                        if (idx > -1) {
                            let eidx = this.props.data[idx].events.findIndex(e => e.id === this.state.event.events[0].id)
                            let event = Object.assign({}, { ...this.state.event, events: [Object.assign({}, { ...this.state.event.events[0], iaminterested: false, iamattending: this.state.event.events[0].iamattending ? false : true, attending: this.state.event.events[0].iamattending ? this.state.event.events[0].attending - 1 : ((this.state.event.events[0].attending || 0) + 1), interested: this.state.event.events[0].iaminterested ? this.state.event.events[0].iaminterested - 1 : (this.state.event.events[0].interested || 0) })] })
                            let newVenue = Object.assign({}, { ...this.props.data[idx], events: [...this.props.data[idx].events.splice(0, eidx), { ...this.props.data[idx].events[eidx], iaminterested: false, iamattending: this.props.data[idx].events[eidx].iamattending ? false : true, attending: this.props.data[idx].events[eidx].iamattending ? this.props.data[idx].events[eidx].attending - 1 : ((this.props.data[idx].events[eidx].attending || 0) + 1), interested: this.props.data[idx].events[eidx].iaminterested ? this.props.data[idx].events[eidx].iaminterested - 1 : (this.props.data[idx].events[eidx].interested || 0) }, ...this.props.data[idx].events.splice(eidx + 1)] })
                            this.props.setData([...this.props.data.slice(0, idx), newVenue, ...this.props.data.slice(idx + 1)])
                            this.setState({ event })
                            this.handleFilter()
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                    })
            } else {
                this.handleNavigate('main')
            }
        }
    }

    handleInterest() {
        if (this.state.event) {
            if (this.props.isAuthenticated) {
                API.put('lt', `interest/${this.state.event.events[0].id}`, { body: { status: 'interested', local: moment().format('YYYY-MM-DD HH:mm') } })
                    .then(resp => {
                        let idx = this.props.data.findIndex(d => d.id === this.state.event.id)
                        if (idx > -1) {
                            let eidx = this.props.data[idx].events.findIndex(e => e.id === this.state.event.events[0].id)
                            let event = Object.assign({}, { ...this.state.event, events: [Object.assign({}, { ...this.state.event.events[0], iamattending: false, iaminterested: this.state.event.events[0].iaminterested ? false : true, interested: this.state.event.events[0].iaminterested ? this.state.event.events[0].interested - 1 : ((this.state.event.events[0].interested || 0) + 1), attending: this.state.event.events[0].iamattending ? this.state.event.events[0].attending - 1 : (this.state.event.events[0].attending || 0) })] })
                            let newVenue = Object.assign({}, { ...this.props.data[idx], events: [...this.props.data[idx].events.splice(0, eidx), { ...this.props.data[idx].events[eidx], iamattending: false, iaminterested: this.props.data[idx].events[eidx].iaminterested ? false : true, interested: this.props.data[idx].events[eidx].iaminterested ? this.props.data[idx].events[eidx] - 1 : ((this.props.data[idx].events[eidx].interested || 0)), attending: this.props.data[idx].events[eidx].iamattending ? this.props.data[idx].events[eidx].attending - 1 : (this.props.data[idx].events[eidx].attending || 0) }, ...this.props.data[idx].events.splice(eidx + 1)] })
                            this.props.setData([...this.props.data.slice(0, idx), newVenue, ...this.props.data.slice(idx + 1)])
                            this.setState({ event })
                            this.handleFilter()
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                    })
            } else {
                this.handleNavigate('main')
            }
        }
    }

    handleVenueSelected(v) {
        this.setState({ venue: v, event: null, mapsize: 'half', menu: { ...this.state.menu, right: 'map' }, center: v.address.coord, zoom: 15 })
        this.scrollToRef(this.FanCards)
    }

    handleSmallSelected(type) {
        if (type === 'venue') {
            this.setState({ event: null, mapsize: 'half', menu: { ...this.state.menu, right: 'map' }, center: this.state.venue.address.coord })
        } else this.setState({ venue: null, mapsize: 'half', menu: { ...this.state.menu, right: 'map' }, center: this.state.event.address.coord })

    }

    handleCloseMarker() {
        this.setState({ event: null, venue: null })
    }

    handleFollow(id) {
        if (this.state.venue) {
            if (this.props.isAuthenticated) {
                API.put('lt', `interest/${this.state.venue.id}`, { body: { status: 'following' } })
                    .then(resp => {
                        let idx = this.props.data.findIndex(d => d.id === this.state.venue.id)
                        if (idx > -1) {
                            let newVenue = { ...this.props.data[idx], iamfollowing: this.props.data[idx].iamfollowing ? !this.props.data[idx].iamfollowing : true, followers: this.props.data[idx].iamfollowing ? this.props.data[idx].followers - 1 : this.props.data[idx].followers + 1 }
                            this.props.setData([...this.props.data.slice(0, idx), newVenue, ...this.props.data.slice(idx + 1)])
                            this.setState({ venue: { ...this.state.venue, iamfollowing: this.state.venue.iamfollowing ? !this.state.venue.iamfollowing : true, followers: this.state.venue.iamfollowing ? this.state.venue.followers - 1 : ((this.state.venue.followers || 0) + 1) } })
                            this.handleFilter()
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                    })
            } else {
                this.handleNavigate('main')
            }
        }
    }

    handleLeftMenu() {
        this.handleNavigate('main')
    }

    handleGiglit() {
        this.giglit()
    }

    handleRightMenu() {
        if (this.state.menu.right === 'list') {
            this.setState({ event: null, venue: null })
            this.handleNavigate('list')
        } else {
            this.setState({
                event: null,
                venue: null,
                menu: { ...this.state.menu, right: (this.state.event || this.state.venue) ? 'list' : 'map' },
                zoom: 10
            })
        }
    }

    handleGoToCard() {
        if (this.state.event) {
            this.handleNavigate(`event/${this.state.event.events[0].id}`)
        } else if (this.state.venue) {
            this.handleNavigate(`venue/${this.state.venue.id}`)
        } else return false
    }

    handleNavigate(page) {
        if (!this.state.isLoading) {
            this.props.onAddHistory('/')
            const { navigate } = this.props
            navigate(`/${page}`)
        }
    }

    handleVPinSelected(pin) {
        this.setState({
            venue: pin,
            event: null,
            center: pin && pin.address && pin.address.coord ? pin.address.coord : this.state.center,
            menu: { ...this.state.menu, right: pin ? 'map' : 'list' }
        })
    }

    handleEPinSelected(pin) {
        console.log(pin)
        this.setState({
            venue: null,
            event: pin,
            center: pin && pin.address && pin.address.coord ? pin.address.coord : this.state.center,
            menu: { ...this.state.menu, right: pin ? 'map' : 'list' }
        })
    }

    async giglit() {
        if (this.state.filteredData) {
            let cnt = this.state.filteredData.events.length + this.state.filteredData.venues.length
            if (cnt > 1) {
                let int = (Math.floor(Math.random() * cnt) + (cnt / 2.5)) * 300
                let nxt = 50
                let idx = Math.floor(Math.random() * cnt)
                let eid = null
                do {
                    int -= nxt
                    if (idx === cnt - 1) {
                        idx = 0
                    } else idx += 1
                    if (idx >= this.state.filteredData.events.length) {
                        eid = null
                        this.setState({
                            mapsize: 'half',
                            event: null,
                            venue: this.state.filteredData.venues[idx - this.state.filteredData.events.length],
                            center: this.state.filteredData.venues[idx - this.state.filteredData.events.length].address && this.state.filteredData.venues[idx - this.state.filteredData.events.length].address.coord ? this.state.filteredData.venues[idx - this.state.filteredData.events.length].address.coord : this.props.myLoc,
                            menu: { ...this.state.menu, right: 'map' }
                        })
                    } else if (this.state.filteredData.venues.length > 0) {
                        const tmp = idx
                        let vidx = this.state.filteredData.venues.findIndex(v => this.state.filteredData.events[tmp].venue === v.id)
                        let eidx = this.state.filteredData.venues[vidx].events.findIndex(e => e.id === this.state.filteredData.events[tmp].id)
                        eid = this.state.filteredData.events[tmp].id
                        this.setState({
                            mapsize: 'half',
                            venue: null,
                            event: Object.assign({}, { ...this.state.filteredData.venues[vidx], events: [{ ...this.state.filteredData.venues[vidx].events[eidx] }] }),
                            center: this.state.filteredData.events[idx].coord || this.props.myLoc
                        })
                    }
                    await this.spin(nxt += 50)
                } while (int > 0)
                if (eid) this.handleEventSelected(eid)
            }
        }
    }

    spin = (int) => new Promise(resolve => {
        setTimeout(resolve, int);
    });

    getData(coord, dist) {
        if (coord || this.props.myLoc) {
            this.setState({ searchChanged: false, filteredData: null })
            let qry = `lat=${coord ? coord.lat : this.props.myLoc.lat}&lng=${coord ? coord.lng : this.props.myLoc.lng}&dist=${dist || this.state.dist}`
            if (this.state.start && this.state.end) {
                qry += `&start=${this.state.start}&end=${this.state.end}`
            } else qry += `&start=${moment().format('YYYY-MM-DD HH:mm')}`
            if (this.state.next) qry += `&next=${this.state.next}`
            if(this.props.user && this.props.user.zone) {
                qry += `&zone=${this.props.user.zone}`
            } else if (this.props.user && this.props.user.zip) qry += `&zip=${this.props.user.zip}`
            
            //qry += `&node=Activities&cat=Tasting`
            //if (this.state.node) qry += `&node=${this.state.node}`
            //if (this.state.cat && this.state.cat.length > 0) qry += `&cat=${this.state.cat.toString()}`
            //if (this.state.amenities && this.state.amenities.length > 0) qry += `&amenities=${this.state.amenities.toString()}`
            const headers = this.props.isAuthenticated ? null : { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } }
            API.get('lt', `${this.props.isAuthenticated ? 'fanstuff' : 'userstuff'}?${qry}`, headers)
                .then(resp => {
                    this.props.setData(resp.data || [])
                    this.setState({ lastSearch: { start: this.state.start, end: this.state.end, dist: this.state.dist }, isLoading: false, next: resp.data.next || null })
                    this.handleFilter(resp.data || [])
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ isLoading: false, next: null })
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        }
    }

    getVenueMarker(category) {
        switch (category) {
            case 'Bar':
                return Beer
            case 'Church':
                return Church
            case 'Comedy Club':
                return ComedyClub
            case 'Nightclub':
                return Club
            case 'Restaurant':
                return Food
            default:
                return Venue
        }
    }

    getEventMarker(summary) {
        if (summary.indexOf('Band') > -1) return Music
        switch (summary) {
            case 'Art':
                return Crafts
            case 'Booth':
                return Booth
            case 'Bounce House':
                return Bounce
            case 'Comedian':
                return Comedy
            case 'Crafting':
                return Crafts
            case 'DJ':
                return DJ
            case 'Duet':
                return Music
            case 'Group':
                return Music
            case 'Karaoke':
                return Mic
            case 'Paint':
                return Paint
            case 'Party':
                return Party
            case 'Performer':
                return Performer
            case 'Solo Artist':
                return Music
            case 'Tasting':
                return Tasting
            case 'Trivia':
                return Mic
            case 'Watch Party':
                return Party
            default:
                return Crowd
        }
    }

    getMarkers() {
        if (this.state.filteredData) {
            //clean this up!!!
            let markers = []
            this.state.filteredData.venues.filter(d => d.address && d.address.coord && this.state.filteredData.events.findIndex(e => e.venue === d.id) === -1).forEach((v, vidx) => {
                markers.push(<Marker onClick={() => this.handleVPinSelected(v)} key={`vpin-${vidx}`}
                    position={v.address.coord}
                    animation={2}
                    title={v.name}
                    icon={{
                        url: this.getVenueMarker(v.category || ''),
                        scaledSize: { height: 40, width: 40 }
                    }} />)
            })
            const events = JSON.parse(JSON.stringify(this.state.filteredData.events.filter(d => d.coord)))
            events.sort((a, b) => {
                if (moment(a.startTime).isAfter(moment(b.startTime))) return -1
                if (moment(a.startTime).isBefore(moment(b.startTime))) return 1
                return 0
            }).forEach((e, eidx) => {
                let idx = this.state.filteredData.venues.findIndex(v => v.id === e.venue)
                if (idx > -1) {
                    markers.push(<Marker onClick={() => this.handleEPinSelected({ ...this.state.filteredData.venues[idx], events: [e] })} key={`epin-${eidx}`}
                        position={e.coord}
                        animation={2}
                        title={e.title}
                        icon={{
                            url: this.getEventMarker(e.summary || ''),
                            scaledSize: { height: 45, width: 45 }
                        }} />)
                }
            })
            return markers
        } else return []
    }

    getNoResults() {
        return <div className='Fan-NoMap-Container'>
            <div className='Fan-NoMap-Icon'><TbFilterSearch /></div>
            <div className='Fan-NoMap-Text'>No Matching Results</div>
            <div className='Fan-NoResults-Text'>Refine your search.</div>
        </div>
    }

    scrollToRef = ref => {
        ref.current.scroll({
            top: 0,
            behavior: 'smooth'
        })
    }

    render() {
        //console.log(this.state, this.props)
        return <div className='Fan-Container'>
            <TopMenu
                mode='fan'
                onMode={this.handleModeChange}
                loc={this.state.loc}
                locIsGood={this.state.locIsGood}
                onLocCheck={this.handleLocationCheck}
                onLocChanged={this.handleLocChanged}
                dist={this.state.dist}
                onDistChange={this.handleDistChanged}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSearch={this.handleDisplaySearch}
                onSettings={this.handleDisplaySettings}
                options={this.state.options}
                month={this.state.month}
                days={this.state.days}
                onMonthChanged={this.handleMonthChanged}
                onMonthSelected={this.handleMonthSelected}
                onDaySelected={this.handleDayChanged}
                onOptionsSelected={this.handleOptionSelect}
                pref={this.state.pref}
                displaySearch={this.state.displaySearch}
                displaySettings={this.state.displaySettings}
                onResetOptions={this.handleResetOptions}
                search
                range
                calendar
                onGetMyLoc={this.handleGetMyLoc} />
            <div className='Fan-No-Scroll'>
                <div className={`Fan-Map-Container ${this.state.mapsize === 'full' ? 'Map-Full' : (this.state.mapsize === 'min' ? 'Map-Min' : 'Map-Half')}`}>
                    {
                        this.state.center ? <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                            <GoogleMap
                                mapContainerStyle={{
                                    width: '100%',
                                    height: this.state.mapsize === 'full' ? 'calc(100vh - 325px)' : (this.state.mapsize === 'min' ? '0px' : 'calc(50vh - 195px)'),
                                    borderRadius: '5px'
                                }}
                                center={this.state.center}
                                zoom={this.state.zoom}>
                                <Marker
                                    position={this.props.myLoc}
                                    animation={1}
                                    title='GigIn from here.' />
                                {
                                    this.getMarkers()
                                }
                                {
                                    this.state.venue && this.state.mapsize === 'full' && <InfoWindow position={this.state.venue.address.coord} onCloseClick={() => this.handleCloseMarker()}>
                                        <div style={{ overflow: 'hidden' }} onClick={() => this.handleSmallSelected('venue')}><SmallCard data={this.state.venue} type='venue' /></div>
                                    </InfoWindow>
                                }
                                {
                                    this.state.event && this.state.mapsize === 'full' && <InfoWindow position={this.state.event.address.coord} onCloseClick={() => this.handleCloseMarker()}>
                                        <div style={{ overflow: 'hidden' }} onClick={() => this.handleSmallSelected('event')}><SmallCard data={this.state.event.events[0]} type='event' /></div>
                                    </InfoWindow>
                                }
                            </GoogleMap>
                        </LoadScript> : <div className='Fan-NoMap-Container'>
                            <div className='Fan-NoMap-Icon'><TbFilterSearch /></div>
                            <div className='Fan-NoMap-Text'>Set Your GigLocation!</div>
                        </div>
                    }
                </div>
                {this.state.center && <div className='Fan-Swipe-Container'>
                    <div className='Fan-HotTopics-Container'>
                        <div className={`Fan-HotTopics-Item${this.state.topic === 'restaurant' ? ' Fan-HotTopics-Venue' : ''}`} onClick={() => this.handleHotTopics('restaurant')}>
                            <MdDining />
                            <div className={`Fan-Hotipics-Text`}>Dining</div>
                        </div>
                        <div className={`Fan-HotTopics-Item${this.state.topic === 'bar' ? ' Fan-HotTopics-Venue' : ''}`} onClick={() => this.handleHotTopics('bar')}>
                            <CiBeerMugFull />
                            <div className='Fan-Hotipics-Text'>Bar</div>
                        </div>
                        <div className={`Fan-HotTopics-Item${this.state.topic === 'dj' ? ' Fan-HotTopics-Event' : ''}`} onClick={() => this.handleHotTopics('dj')}>
                            <FaRecordVinyl />
                            <div className='Fan-Hotipics-Text'>DJ</div>
                        </div>
                        <div className={`Fan-HotTopics-Item${this.state.topic === 'karaoke' ? ' Fan-HotTopics-Event' : ''}`} onClick={() => this.handleHotTopics('karaoke')}>
                            <GiMicrophone />
                            <div className='Fan-Hotipics-Text'>Karaoke</div>
                        </div>
                        <div className={`Fan-HotTopics-Item${this.state.topic === 'music' ? ' Fan-HotTopics-Event' : ''}`} onClick={() => this.handleHotTopics('music')}>
                            <GiGuitar />
                            <div className='Fan-Hotipics-Text'>Music</div>
                        </div>
                        <div className={`Fan-HotTopics-Item${this.state.topic === 'trivia' ? ' Fan-HotTopics-Event' : ''}`} onClick={() => this.handleHotTopics('trivia')}>
                            <MdOutlineQuestionAnswer />
                            <div className='Fan-Hotipics-Text'>Trivia</div>
                        </div>
                        <div className={`Fan-HotTopics-Item${this.state.topic === 'fire' ? ' Fan-HotTopics-Event' : ''}`} onClick={() => this.handleHotTopics('fire')}>
                            <BsFire />
                            <div className='Fan-Hotipics-Text'>Hot Topic</div>
                        </div>
                        <div className={`Fan-HotTopics-Item`} onClick={() => this.handleHotTopics(null)}>
                            <BiReset />
                            <div className='Fan-Hotipics-Text'>Reset</div>
                        </div>
                    </div>
                    <div className='Fan-Swipe-Bar-Row' onClick={() => this.handleSwipeClick()}>
                        <div className='Fan-Swipe-Flex'>
                            <div className='Fan-Swipe-Arrow'><FaArrowDownUpAcrossLine /></div>
                            <div className='Fan-Swipe-Bar'></div>
                            <div className='Fan-Swipe-Arrow'><FaArrowDownUpAcrossLine /></div>
                        </div>
                    </div>
                </div>}
                {
                    this.state.mapsize === 'half' && this.state.view === 'map' && <div ref={this.FanCards} className={`Fan-Cards`}>
                        {
                            !this.state.venue && !this.state.event && this.state.filteredData && this.state.filteredData.events && this.state.filteredData.events.map((e, eidx) => {
                                return <div key={`mwevent-${eidx}`} onClick={() => this.handleEventSelected(e.id)} style={{ cursor: 'pointer' }}><MiniWide type='event' data={e} near={this.props.user && this.props.user.dist && parseInt(this.props.user.dist) >= (e.distance || 0)} /></div>
                            })
                        }
                        {
                            !this.state.venue && !this.state.event && this.state.filteredData && this.state.filteredData.venues && this.state.filteredData.venues.map((v, vidx) => {
                                return <div key={`mwvenue-${vidx}`} onClick={() => this.handleVenueSelected(v)} style={{ cursor: 'pointer' }}><MiniWide type='venue' data={v} near={this.props.user && this.props.user.dist && parseInt(this.props.user.dist) >= (v.distance || 0)} /></div>
                            })
                        }
                        {
                            !this.state.venue && !this.state.event && this.state.next && <div className='Fan-More-Container' onClick={() => this.getData()}>
                                <div className='Fan-More-Divider'></div>
                                <div className='Fan-More-Button'>More</div>
                                <div className='Fan-More-Divider'></div>
                            </div>
                        }
                        {
                            this.state.venue && <Card data={this.state.venue} type='venue' onSelected={this.handleGoToCard} onFollow={this.handleFollow} near={this.props.user && this.props.user.dist && parseInt(this.props.user.dist) >= (this.state.venue.distance ? this.state.venue.distance : 0)} />
                        }
                        {
                            this.state.event && <Card data={this.state.event} type='event' onSelected={this.handleGoToCard} onInterest={this.handleInterest} onAttending={this.handleAttending} near={this.props.user && this.props.user.dist && parseInt(this.props.user.dist) >= (this.state.event.dist || 0)} />
                        }
                        {
                            this.state.filteredData && ((this.state.filteredData.venues && this.state.filteredData.venues.length > 0) || (this.state.filteredData.events && this.state.filteredData.events.length > 0)) ? null : this.getNoResults()
                        }
                    </div>
                }
            </div>
            <BottomMenu menu={this.state.menu} onLeft={this.handleLeftMenu} onGiglit={this.handleGiglit} onRight={this.handleRightMenu} />
        </div>
    }
}

export default withRouter(Fan)