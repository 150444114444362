import React from 'react'
import { FacebookShareButton, FacebookIcon } from 'react-share'

const ShareFB = (props) => {
    //console.log(props)
    return <div>
        <FacebookShareButton url={props.url} quote={props.quote} hashtag={props.hashtag} description={props.description}>
            <FacebookIcon size={props.size} round />
        </FacebookShareButton>
    </div>
}

export default ShareFB