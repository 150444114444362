import { Component } from 'react'
import { API } from 'aws-amplify'
import Calendar from '../components/calendar'
import moment from 'moment'
import QRCode from 'qrcode.react'
import SmallCard from '../components/smallCard'
import TopMenu from '../components/topMenu'
import Carousel from 'react-bootstrap/Carousel'
import Form from "react-bootstrap/Form"
import LoaderButton from '../components/loaderButton'
import withRouter from '../components/withRouter'
import { FaArrowLeft, FaRegQuestionCircle, FaStar, FaRegGrinStars, FaHeart, FaRegHeart, FaRegStar, FaUser, FaLink, FaUserAltSlash, FaRegCheckSquare } from 'react-icons/fa'
import { FaHeartCircleCheck, FaSquareTwitter, FaSquareXTwitter, FaSquareInstagram, FaSquareFacebook, FaYoutube, FaSquareXmark, FaPhone, FaFax } from 'react-icons/fa6'
import { IoLogoVenmo } from 'react-icons/io5'
import { SiCashapp } from 'react-icons/si'
import { BsYelp, BsLinkedin } from 'react-icons/bs'
import { FcInvite } from 'react-icons/fc'
import { RiMailSendLine } from 'react-icons/ri'
import { LuCalendarClock, LuCalendarX2 } from 'react-icons/lu'
import { MdOutlineAttachMoney, MdPublic, MdPublicOff, MdEmail } from 'react-icons/md'
import { GiThreeFriends } from 'react-icons/gi'
import Logo from '../images/GigLoca_Logo_Light.png'
import ShareFB from '../components/share-fb'
import ShareX from '../components/share-x'
import ShareReddit from '../components/share-reddit'
import ShareWhat from '../components/share-what'
import './node.css'

class Contributor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isSaving: false,
            bounce: false,
            data: null,
            node: null,
            displaySettings: false,
            id: null,
            refresh: false,
            displayRate: false,
            stars: null,
            more: null,
            comments: '',
            invite: '',
            userFailed: false,
            userPassed: false,
            lastInvite: '',
            ownership: false,
            month: moment().date(1).format('YYYY-MM-DD'),
            days: [],
            altDays: [],
            selDay: null,
            start: '',
            stop: '',
            events: [],
            emailSel: false,
            shared: false
        }

        this.handleEventSelect = this.handleEventSelect.bind(this)
        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleDayChanged = this.handleDayChanged.bind(this)
        this.handleMonthChanged = this.handleMonthChanged.bind(this)
        this.handleMonthSelected = this.handleMonthSelected.bind(this)
    }

    componentDidMount() {
        const { path, search } = this.props
        const id = path.id || null
        const queryParams = new URLSearchParams(search.search)
        this.setState({ refresh: queryParams.get('refresh') || false })
        if (id) {
            if (this.props.myLoc) {
                this.getData(id, this.props.myLoc, moment().date(1).format('YYYY-MM-DD'), moment().date(1).add(1, 'month').format('YYYY-MM-DD'))
            } else {
                const gl = navigator.geolocation
                gl.getCurrentPosition(position => {
                    this.getData(id, { lat: position.coords.latitude, lng: position.coords.longitude }, moment().date(1).format('YYYY-MM-DD'), moment().date(1).add(1, 'month').format('YYYY-MM-DD'))
                    this.props.onMyLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                }, err => {
                    this.getData(id, null, moment().date(1).format('YYYY-MM-DD'), moment().date(1).add(1, 'month').format('YYYY-MM-DD'))
                })
            }
        } else this.handleNavigate('')
    }

    handleNavigate(page) {
        if (this.state.id) this.props.onAddHistory(`/contributor/${this.state.id}`)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        this.props.onBack(this.state.refresh)
    }

    handleMore(more) {
        if (this.state.more === more) {
            this.setState({ more: null })
        } else this.setState({ more })
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile')
    }

    handleDisplaySettings() {
        this.setState({ displaySearch: false, displaySettings: !this.state.displaySettings })
    }

    handleModeChange(mode) {
        this.setState({ displaySettings: false })
        if (mode === 'contactus') {
            this.handleNavigate('contactus')
        } else {
            if (mode !== 'fan') {
                this.props.setMode(mode)
                this.handleNavigate(`my/${mode}s`)
            }
        }
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications')
    }

    handleEventSelect(eid) {
        this.handleNavigate(`event/${eid}`)
    }

    handleFollow(nid) {
        if (this.props.isAuthenticated) {
            API.put('lt', `interest/${nid}`, { body: { status: 'following' } })
                .then(resp => {
                    if (nid === this.state.node.id) {
                        this.setState({ node: { ...this.state.node, iamfollowing: this.state.node.iamfollowing ? false : true, followers: this.state.node.iamfollowing ? this.state.node.followers - 1 : (this.state.node.followers || 0) + 1 }, refresh: !this.state.refresh })
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        } else {
            this.handleNavigate('main')
        }
    }

    handleRate(stars) {
        this.setState({ displayRate: true, stars: stars ? stars : (this.state.stars || this.state.data.irated || 1) })
    }

    handleChange = event => {
        if (event.target.id === 'comments') {
            this.setState({ comments: event.target.value.replace(/[<>]/g, '').substring(0, 249) })
        } else if (event.target.id === 'invite') {
            this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@. ]/g, "").toLowerCase(), userFailed: false, userPassed: false, emailSel: false })
        }
    }

    handleSaveRate = (event) => {
        event.preventDefault()
        if (this.props.isAuthenticated) {
            this.setState({ isSaving: true })
            API.post('lt', `review`, { body: { node: this.state.node.id, type: 'node', stars: this.state.stars, comments: this.state.comments, local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    this.setState({ displayRate: false, isSaving: false, refresh: true })
                    this.getData(this.state.id)
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        } else {
            this.setState({ bounce: true })
            setTimeout(() => this.handleNavigate('main'), 3500)
        }
    }

    handleCancel() {
        this.setState({ displayRate: false, stars: this.state.node.irated || null, comments: this.state.node.comments || '' })
    }

    handleInvite() {
        if (this.state.invite.toLowerCase() !== this.state.lastInvite.toLowerCase()) {
            API.post('lt', `invite`, { body: { email: this.state.invite.toLowerCase(), type: 'share', cid: this.state.id, title: this.state.node.name, local: moment().format("YYYY-MM-DD HH:mm") } })
                .then(resp => {
                    this.setState({ userFailed: false, userPassed: true, invite: '', lastInvite: this.state.invite })
                })
                .catch(err => {
                    this.setState({ msg: "Unable to share with user.", userFailed: true, userPassed: false })
                })
        } else this.setState({ msg: "Duplicate user request.", userFailed: true, userPassed: false })
    }
        
    handleShareAll() {
        if (!this.state.shared) {
            API.post('lt', `invite`, { body: { type: 'friends', cid: this.state.id, title: this.state.node.name, local: moment().format("YYYY-MM-DD HH:mm") } })
                .then(resp => {
                    this.setState({ userFailed: false, userPassed: true, invite: '', shared: true })
                })
                .catch(err => {
                    this.setState({ msg: "Unable to share with Entourage.", userFailed: true, userPassed: false })
                })
        }
    }

    handleRequestOwnership() {
        if (this.props.isAuthenticated) {
            const body = { type: 'ownership', nid: this.state.id }
            API.post('lt', 'invite', { body })
                .then(resp => {
                    this.setState({ ownership: true })
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        } else {
            this.setState({ bounce: true })
            setTimeout(() => this.handleNavigate('main'), 3500)
        }
    }

    ratingIsValid() {
        return this.state.stars || this.state.node.irated
    }

    handleDayChanged(selDay) {
        this.setState({ selDay })
    }

    handleMonthChanged(dir) {
        let month = moment(this.state.month)
        if (dir === 'back') {
            month.subtract(1, 'month')
        } else if (dir === 'next') {
            month.add(1, 'month')
        }
        const start = moment(month).format('YYYY-MM-DD')
        const stop = moment(month).add(1, 'month').format('YYYY-MM-DD')
        this.setState({ month: month.format('YYYY-MM-DD'), start, stop, selDay: null })
        this.getData(this.state.id, this.props.myLoc || null, start, stop)
    }

    handleMonthSelected() {
        this.setState({ selDay: null })
    }

    handleEmailSelected(email) {
        this.setState({ invite: email, emailSel: true })
    }

    getData(id, coord, start, stop) {
        const headers = this.props.user && this.props.user.active ? null : { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } }
        API.get('lt', `${this.props.user && this.props.user.active ? 'fan' : 'user'}stuff?nid=${id}${coord ? `&lat=${coord.lat}&lng=${coord.lng}` : ''}&start=${start}&end=${stop}`, headers)
            .then(resp => {
                if (resp.data[0]) {
                    let days = []
                    let events = []
                    resp.data.filter(d => d.nodetype === 'Venue' && d.events && d.events.length > 0).forEach(v => events = [...events, ...v.events])
                    events.sort((a, b) => {
                        if (a.startTime && b.startTime) {
                            if (moment(a.startTime).isBefore(moment(b.startTime))) return -1
                            if (moment(a.startTime).isAfter(moment(b.startTime))) return 1
                            return 0
                        } else return -1
                    }).forEach(e => {
                        if (e.startTime && e.stopTime) {
                            for (let x = moment(e.startTime).date(); x <= moment(e.stopTime).date(); x++) {
                                days.push(x)
                            }
                        }
                    })
                    let idx = resp.data.findIndex(d => d.id === id)
                    let node = null
                    let data = []
                    if (idx > -1) {
                        node = resp.data[idx]
                        data = resp.data.filter((d, didx) => didx !== idx)
                    } else {
                        data = resp.data
                        const eid = resp.data[0].events.findIndex(e => e.nodes && e.nodes.findIndex(n => n.id === id) > -1)
                        if (eid > -1) {
                            const nid = resp.data[0].events[eid].nodes.findIndex(n => n.id === id)
                            if (nid > -1) {
                                node = resp.data[0].events[eid].nodes[nid]
                            }
                        }
                    }
                    if (node) {
                        this.setState({ data, node, id, stars: node.irated || null, comments: node.comments || '', days, events })
                    } else this.handleNavigate('')
                }
            })
            .catch(err => {
                if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                else this.handleBack()
            })
    }

    getSelectedStarsArray(s) {
        let stars = s || this.state.stars || 0
        let selected = []
        for (let x = 0; x < stars; x++) {
            selected.push(x + 1)
        }
        return selected
    }

    getUnselectedStarsArray(s) {
        let stars = s || this.state.stars || 1
        let unselected = []
        for (let x = parseInt(stars); x < 5; x++) {
            let s = x + 1
            unselected.push(s)
        }
        return unselected
    }

    getValidation(field) {
        if (field === 'comments') {
            return true
        } else if (field === 'invite') {
            return this.state.invite.length > 2 && this.state.invite.indexOf('@') > 0 && this.state.invite.indexOf('.') > 1
        }
    }

    getBGIcon() {
        if (this.state.node && this.state.node.category) {
            switch (this.state.data.category) {

                default:
                    return <FaUser />
            }
        } else return <FaRegQuestionCircle />
    }

    getEvents() {
        let events = []
        this.state.data.forEach(v => {
            if (v.events) events = [...events, ...v.events]
        })
        if (events.length > 0) {
            return <div className='Node-Row Node-Flex-Wrap'>{events.map((event, eidx) => {
                return <div key={`ue-${eidx}`} className='Node-Card'><SmallCard type='event' data={event} onSelect={this.handleEventSelect} /></div>
            })}</div>
        } else return null
    }

    getSocialIcon(link) {
        let thisLink = link || this.state.link
        if (thisLink.length > 8) {
            let t = /twitter./i
            let fb = /facebook./i
            let inst = /instagram./i
            let cash = /cash.app/i
            let v = /venmo./i
            let u = /youtube./i
            let y = /yelp./i
            let l = /linkedin./i
            if (t.test(thisLink)) {
                return <div style={{ marginTop: '10px' }} className='Node-Flex'><FaSquareTwitter /><FaSquareXTwitter /></div>
            } else if (fb.test(thisLink)) {
                return <FaSquareFacebook />
            } else if (inst.test(thisLink)) {
                return <FaSquareInstagram />
            } else if (cash.test(thisLink)) {
                return <SiCashapp />
            } else if (v.test(thisLink)) {
                return <IoLogoVenmo />
            } else if (u.test(thisLink)) {
                return <FaYoutube />
            } else if (l.test(thisLink)) {
                return <BsLinkedin />
            } else if (y.test(thisLink)) {
                return <BsYelp />
            } else return <FaLink />
        } else return <FaLink />
    }

    getLinks() {
        if (this.state.node && this.state.node.linkArray) {
            let t = /twitter./i
            let fb = /facebook./i
            let inst = /instagram./i
            let cash = /cash.app/i
            let v = /venmo./i
            let u = /youtube./i
            let y = /yelp./i
            let l = /linkedin./i
            let sm = []
            let links = []
            this.state.node.linkArray.forEach(link => {
                if (t.test(link) || fb.test(link) || inst.test(link) || cash.test(link) || v.test(link) || y.test(link) || l.test(link) || u.test(link)) {
                    sm.push((`https://${link}`).replace(/(https|http)/ig, 'https'))
                } else links.push((`https://${link}`).replace(/(https|http)/ig, 'https'))
            })
            if (sm.length + links.length > 0) {
                return <div className='Node-Row'>
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Addr-Icon'><FaLink /></div>
                        <div className='Node-Divider-Text'>Social Media & Links</div>
                        <div className='Node-Divider'></div>
                    </div>
                    <div className='Node-Flex-Wrap'>
                        {
                            sm.map((sl, slidx) => {
                                return <div key={`sml-${slidx}`} className='Node-SM-Icons'><a href={sl} target='_blank' rel="noreferrer" title={sl}>{this.getSocialIcon(sl)}</a></div>
                            })
                        }
                    </div>
                    {
                        links.map((link, lidx) => {
                            return <div key={`link-${lidx}`} className='Node-Row Node-Flex'><div className='Node-Addr-Icon'>{this.getSocialIcon(link)}</div><a href={link} target='_blank' rel="noreferrer" className='My-Link'>{link}</a></div>
                        })
                    }
                </div>
            }
        } else return null
    }

    getOpHours() {
        let days = []
        this.state.node.operationArray.forEach(o => days = [...days, ...o.values.map(v => { return { open: o.start || null, close: o.stop || null, day: v } })])
        return days.map((d, dayidx) => {
            return <div key={`day-${dayidx}`} className='Node-Item'>{`${d.day} ${d.open ? `${moment(`2023-01-01 ${d.open}`).format('h:mm A')} - ${moment(`2023-01-01 ${d.close}`).format('h:mm A')}` : 'Closed'}`}</div>
        })
    }

    getBounce() {
        return <div className='Node-Content'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Pref-Bounce'>For your All-Access Pass to all that Gigloca has to offer, create your account or sign in to Gigloca.</div>
        </div>
    }

    render() {
        //console.log(this.state, this.props)
        return this.state.node ? <div className='Node-Container Node-Contributor'>
            <TopMenu
                mode='fan'
                onMode={this.handleModeChange}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSettings={this.handleDisplaySettings}
                displaySettings={this.state.displaySettings}
                pref={null} />
            {
                this.state.bounce ? this.getBounce() : <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
                    <div className='Node-Content-2'>
                        {this.state.node.imageArray ? <div className='Node-Row'><Carousel className='Node-Carousel' controls={false} touch={true} indicators={true} interval={2000} fade>
                            {
                                this.state.node.imageArray.map((ci, imgidx) => {
                                    return <Carousel.Item key={`ci-${imgidx}`}>
                                        <img src={`https://localtalent-upload.s3.amazonaws.com/public/${ci}`} className='Node-CI' alt={this.state.data.name || this.state.data.title || ''} />
                                    </Carousel.Item>
                                })
                            }
                        </Carousel></div> : <div className='Node-Row Node-CI Node-BG-Icon'>{this.getBGIcon()}</div>}
                        <div className='Node-Back'><div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div></div>
                        <div className='Node-Content-3'>
                            <div className='Node-Row Node-Flex-Spread'>
                                <div className='Node-Title'>{this.state.node.name ? this.state.node.name : 'Venue'}</div>
                                {this.state.node.iamfollowing && <div style={{ marginRight: '6px' }} className='Node-Heart'><FaHeartCircleCheck /></div>}
                            </div>
                            <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    this.state.node.genre && this.state.node.genre.map((g, gidx) => {
                                        return <div key={`genre-${gidx}`} className='Node-Item'>{g}</div>
                                    })
                                }
                            </div>
                            {this.state.data.communicationArray && this.state.data.communicationArray.length > 0 && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>{this.state.node} Contact Information</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.communicationArray && this.state.data.communicationArray.length > 0 ? this.state.data.communicationArray.map((comm, cidx) => {
                                return <div key={`comm-${cidx}`} className='Node-Row Node-Flex My-Link'><div style={{ textDecoration: 'none' }} className='My-Link-Text'>{comm.communicationType === 'Phone' ? <FaPhone /> : (comm.communicationType === 'Fax' ? <FaFax /> : <MdEmail />)} {comm.communicationType === 'Email' ? <a href={`mailto:${comm.contact}`}>{comm.contact}</a> : (comm.communicationType === 'Phone' ? <a href={`tel:${comm.contact.replace(/[\D\s]/g, '')}`}>{comm.contact}</a> : comm.contact)} {comm.consent ? <MdPublic /> : <MdPublicOff />}</div></div>
                            }) : null}
                            {this.getLinks()}
                            {this.state.node.operationArray && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Addr-Icon'><LuCalendarClock /></div>
                                <div className='Node-Divider-Text'>Hours of Operation</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.node.operationArray && <div className='Node-Row Node-Flex-Left'>
                                {this.getOpHours()}
                            </div>}
                            {this.state.data.blackoutArray && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Addr-Icon'><LuCalendarX2 /></div>
                                <div className='Node-Divider-Text'>Blackout Dates</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.blackoutArray && <div className='Node-Row Node-Flex-Wrap'>
                                {this.state.data.blackoutArray.map((ba, baidx) => {
                                    return <div key={`boa-${baidx}`} className="Node-Item">{moment(ba).format('ll')}</div>
                                })}
                            </div>}
                            {this.state.node.rate && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Addr-Icon'><MdOutlineAttachMoney /></div>
                                <div className='Node-Divider-Text'>Preferred Rate</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.node.rate && <div className='Node-Row Node-Flex-Wrap'>
                                <div className='Node-Divider-Text'>{this.state.node.rate.rateValue}</div>
                                <div className='Node-Addr-Icon Node-Divider-Text'>Negotiable: {this.state.node.rate.negotiable ? <FaRegCheckSquare /> : <FaSquareXmark />}</div>
                            </div>}
                            {this.state.node.description && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Description</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.node.description && <div className='Node-Row'>
                                <div className='Node-Details'>{this.state.node.description}</div>
                            </div>}
                            {this.state.node.followers && this.state.node.followers > 0 ? <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Followers</div>
                                <div className='Node-Divider'></div>
                                <div style={{}}>
                                    <div className='Node-Heart Node-Heart-Followers'><FaHeart /></div>
                                    <div className='Node-Heart-Text'>{this.state.node.followers > 1000 ? `${(this.state.node.followers / 1000).toFixed(1)}k` : this.state.node.followers}</div>
                                </div>
                            </div> : null}
                            <div className='Node-Row Node-Flex-Wrap'>
                                <div className='Node-Gigin' onClick={() => this.handleFollow(this.state.node.id)}>{this.state.node.iamfollowing ? 'Unfollow' : 'Follow'}</div>
                                <div style={{ marginLeft: '10px', marginTop: '-5px', color: '#fff' }} className='Node-Heart'>{this.state.node.iamfollowing ? <FaHeart /> : <FaRegHeart />}</div>
                            </div>
                            {this.state.node.stars && this.state.node.stars > 0 ? <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Rating</div>
                                <div className='Node-Divider'></div>
                                <div style={{ marginRight: '5px' }}>
                                    <div className='Node-Stars'><FaStar /></div>
                                    <div className='Node-Stars-Text'>{this.state.node.stars.toFixed(1)}</div>
                                </div>
                            </div> : null}
                            {this.state.node.irated ? <div className='Node-Row Node-Flex-Left'>
                                <div style={{ fontSize: '14pt', marginTop: '-3px' }} className='Node-Divider-Text'><FaRegGrinStars /> I rated</div>
                                <div className='Node-Divider'></div>
                                <div style={{ marginTop: '3px' }} className='Node-Stars Node-Flex-Spread' onClick={() => this.handleRate()}>{this.getSelectedStarsArray().map((ss) => {
                                    return <div key={`irss-${ss}`} className='Node-Stars'><FaStar /></div>
                                })}</div>
                                <div style={{ marginTop: '3px' }} className='Node-Stars Node-Flex-Spread' onClick={() => this.handleRate()}>{this.getUnselectedStarsArray().map((us) => {
                                    return <div key={`irus-${us}`} style={{ color: '#b3b3b3' }} className='Node-Stars'><FaStar /></div>
                                })}</div>
                            </div> : <div className='Node-Row Node-Flex-Wrap Rate-Here-Text Rate-Here-Button' onClick={() => this.handleRate()}><div>Rate This {this.state.node.nodetype} Contributor</div><div><FaRegStar /></div>
                            </div>}
                            {this.state.data.posts && this.state.data.posts.length > 0 ? <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Posts from {this.state.data.posts.name}</div>
                                <div className='Node-Divider'></div>
                                <div className='Node-Divider-Button' onClick={() => this.handleMore('posts')}>{this.state.more === 'posts' ? 'Less' : 'More'}</div>
                            </div> : null}
                            {this.state.displayRate && <div className='Rate-Container'>
                                <form onSubmit={this.handleSaveRate}>
                                    <div style={{ borderBottom: '2px solid yellow', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', paddingBottom: '5px' }} className='Node-Row Node-Flex-Spread'>
                                        {this.getSelectedStarsArray().map((ss) => {
                                            return <div key={`rirss-${ss}`} style={{ marginTop: '5px', cursor: 'pointer' }} className='Node-Stars' onClick={() => this.handleRate(ss)}><FaStar /></div>
                                        })}
                                        {this.getUnselectedStarsArray().map((us) => {
                                            return <div key={`rirus-${us}`} style={{ marginTop: '5px', cursor: 'pointer' }} className='Node-Stars' onClick={() => this.handleRate(us)}><FaRegStar /></div>
                                        })}
                                    </div>
                                    <div className='Node-Row'><Form.Group controlId="comments" className="form-group form-group-lg">
                                        <Form.Label className='Pref-Label'>Comments</Form.Label>
                                        <Form.Control
                                            placeholder="Rating Comments"
                                            as="textarea"
                                            value={this.state.comments}
                                            onChange={event => this.handleChange(event)}
                                        />
                                    </Form.Group></div>
                                    <div className='Node-Row Node-Flex-Spread'>
                                        <div style={{ width: '160px', height: '60px' }}><LoaderButton
                                            size="lg"
                                            disabled={!this.ratingIsValid()}
                                            type="submit"
                                            isLoading={this.state.isSaving}
                                            text='Rate'
                                            loadingText='Saving...'
                                            className='Signup-Button'
                                            style={{ margin: '0px', bottom: '0' }}
                                        /></div>
                                        <div style={{ width: '160px', fontSize: '18pt', fontWeight: 'bold' }} className='Pref-Cancel-Button' onClick={() => this.handleCancel()}>Cancel</div>
                                    </div>
                                </form>
                            </div>}
                            {this.state.data.posts && this.state.data.posts.length > 0 ? <div className={`My-Reviews-Container${this.state.more === 'posts' ? ' My-Reviews-More' : ''}`}>
                                {this.state.data.posts.sort((a, b) => {
                                    if (moment(a.created).isAfter(moment(b.created))) return -1
                                    if (moment(a.created).isBefore(moment(b.created))) return 1
                                    return 0
                                }).map((post, pidx) => {
                                    return <div key={`mpost-${pidx}`} className='My-Review-Container'>
                                        <div className='Node-Row Node-Flex'>
                                            <div style={{ marginRight: '10px' }}>{post.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${post.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div>
                                            <div className='My-Review-Text'>{post.post}</div>
                                            <div style={{ width: '110px', fontSize: '9pt' }} className='My-Review-Text'>{moment(post.created).format('ll')}</div>
                                        </div>
                                    </div>
                                })}
                            </div> : null}
                            {this.state.node.reviews && this.state.node.reviews.length > 0 ? <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Reviews</div>
                                <div className='Node-Divider'></div>
                                <div className='Node-Divider-Button' onClick={() => this.handleMore('reviews')}>{this.state.more === 'reviews' ? 'Less' : 'More'}</div>
                            </div> : null}
                            {this.state.node.reviews && this.state.node.reviews.length > 0 ? <div className={`My-Reviews-Container${this.state.more === 'reviews' ? ' My-Reviews-More' : ''}`}>
                                {this.state.node.reviews.sort((a, b) => {
                                    if (moment(a.created).isAfter(moment(b.created))) return -1
                                    if (moment(a.created).isBefore(moment(b.created))) return 1
                                    return 0
                                }).map((rev, ridx) => {
                                    return <div key={`mrev-${ridx}`} className='My-Review-Container'>
                                        <div className='My-Review-Stars'>
                                            {this.getSelectedStarsArray(parseInt(rev.stars || '1')).map((ss) => {
                                                return <div key={`ss-${ss}`} className='Node-Stars'><FaStar /></div>
                                            })}
                                            {this.getUnselectedStarsArray(parseInt(rev.stars || '1')).map((ss) => {
                                                return <div key={`us-${ss}`} style={{ color: '#b3b3b3' }} className='Node-Stars'><FaStar /></div>
                                            })}
                                        </div>
                                        <div className='Node-Row Node-Flex'>
                                            <div style={{ marginRight: '10px' }}>{rev.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${rev.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div>
                                            <div className='My-Review-Text'>{rev.comments}</div>
                                            <div style={{ width: '110px', fontSize: '9pt' }} className='My-Review-Text'>{moment(rev.created).format('ll')}</div>
                                        </div>
                                    </div>
                                })}
                            </div> : null}
                            <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Calendar of Events</div>
                                <div className='Node-Divider'></div>
                            </div>
                            <Calendar
                                month={this.state.month}
                                onDaySelected={this.handleDayChanged}
                                onMonthChanged={this.handleMonthChanged}
                                onMonthSelected={this.handleMonthSelected}
                                selected={this.state.selDay}
                                days={this.state.days}
                                altDays={this.state.altDays} />
                            {this.state.selDay ? this.state.events && this.state.events.filter(e => moment(e.stopTime).isAfter(moment())).length > 0 && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>{moment(this.state.month).date(this.state.selDay).format('ll')}</div>
                                <div className='Node-Divider'></div>
                            </div> : this.state.events && this.state.events.filter(e => moment(e.stopTime).isAfter(moment())).length > 0 && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Upcoming Events</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.events && this.state.events.filter(e => moment(e.stopTime).isAfter(moment())).length > 0 && <div className='Node-Row Node-Flex-Wrap'>{this.state.events.filter(e => this.state.selDay ? moment(this.state.month).date(this.state.selDay).isSameOrBefore(moment(e.stopTime)) && moment(this.state.month).date(this.state.selDay + 1).isSameOrAfter(e.startTime) : moment(e.stopTime).isAfter(moment())).map((event, eidx) => {
                                return <SmallCard key={`ue-${eidx}`} type='event' data={event} onSelect={this.handleEventSelect} />
                            })}</div>}
                            {this.state.events && this.state.events.filter(e => moment(e.stopTime).isBefore(moment())).length > 0 && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Past Events</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.events && this.state.events.filter(e => moment(e.stopTime).isBefore(moment())).length > 0 && <div className='Node-Row Node-Flex-Wrap'>{this.state.events.filter(e => moment(e.stopTime).isBefore(moment())).map((event, eidx) => {
                                return <SmallCard key={`ue-${eidx}`} type='event' data={event} onSelect={this.handleEventSelect} />
                            })}</div>}
                            {this.state.id && <div className='Node-Row Node-Flex-Wrap'><QRCode value={`https://gigloca.com/contributor/${this.state.id}`} /></div>}
                            <Form.Group controlId="invite" style={{ marginLeft: '5px', marginRight: '5px' }} className="form-group form-group-lg">
                            <Form.Label className='Pref-Label Node-Flex-Spread'><div>Share with a Friend</div><div style={{ border: '2px solid #fff', borderRadius: '5px', padding: '0 3px' }} onClick={() => this.handleShareAll()}>Share with Entourage<GiThreeFriends /></div></Form.Label>
                                <div className='Node-Flex'>
                                    <Form.Control
                                        placeholder="User Email"
                                        type="email"
                                        value={this.state.invite}
                                        onChange={this.handleChange}
                                        isValid={this.getValidation('invite')}
                                    />
                                    <div className={`My-Link-Icon${this.state.invite.length > 0 ? ' My-Link-Icon-Animate' : ''}`} onClick={() => this.handleInvite()}><RiMailSendLine /></div>
                                    {this.state.userFailed ? <div className='My-Link-Icon' style={{ color: '#ff0000' }}><FaUserAltSlash /></div> : (this.state.userPassed || this.state.shared ? <div className='My-Link-Icon'><FcInvite /></div> : null)}
                                </div>
                            </Form.Group>
                            {!this.state.emailSel && this.props.user && this.props.user.friendArray && this.props.user.friendArray.length > 0 && this.state.invite.length > 0 && (this.props.user.friendArray.findIndex(fa => fa.email.indexOf(this.state.invite) > -1) > -1) && <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    this.props.user.friendArray.filter(fa => fa.email.indexOf(this.state.invite) > -1).map((f, fidx) => {
                                        return <div key={`email-${fidx}`} className='Node-Email' onClick={() => this.handleEmailSelected(f.email)}>{f.email}</div>
                                    })
                                }
                            </div>}
                            <div className='Node-Row Node-Flex-Wrap'>
                                <ShareFB url={`https://gigloca.com/contributor/${this.state.id}`} quote={`Join the Fun${this.state.data.name ? ` with ${this.state.data.name}` : ''}!`} hashtag={'#Gigloca'} size={24} />
                                <ShareX url={`https://gigloca.com/contributor/${this.state.id}`} quote={`Join the Fun${this.state.data.name ? ` with ${this.state.data.name}` : ''}!`} hashtags={['#Gigloca']} size={24} />
                                <ShareReddit url={`https://gigloca.com/contributor/${this.state.id}`} title={`Join the Fun${this.state.data.name ? ` with ${this.state.data.name}` : ''}!`} size={24} />
                                <ShareWhat url={`https://gigloca.com/contributor/${this.state.id}`} title={`Join the Fun${this.state.data.name ? ` with ${this.state.data.name}` : ''}!`} size={24} />
                            </div>
                            {!this.state.data.managed && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Is this your Venue?</div>
                                <div className='Node-Divider'></div>
                                <div className="Node-Row Node-Flex-Wrap" style={{ marginTop: '10px' }}>
                                    {this.state.ownership ? <div className='Node-Ownership'>Ownership Change Request Submitted. Gigloca will contact you shortly.  Thank you!</div> : <div className='Pref-Button Pref-Button-Save' style={{ width: '210px' }} onClick={() => this.handleRequestOwnership()}>Request Ownership</div>}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            }
        </div> : <div style={{ padding: '10%' }} className='Empty-Container Node-Venue'><img className='Node-Logo' src={Logo} alt="Gigloca" /></div>
    }
}

export default withRouter(Contributor)