import { Component, createRef } from 'react'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import { FcCancel, FcOk, FcMoneyTransfer } from 'react-icons/fc'
import { HiRefresh } from 'react-icons/hi'
import '../containers/node.css'

class PayConfirm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            status: '',
            id: '',
            css: 'Small'
        }
    }

    PayStatus = createRef()

    componentDidMount() {
        if (this.props.stripe) {
            const { stripe, clientSecret } = this.props;
            if (!stripe) return
            stripe.retrievePaymentIntent(clientSecret.clientSecret)
                .then(({ paymentIntent }) => {
                    this.setState({ status: paymentIntent.status, id: paymentIntent.id, css: 'Small' })
                    setTimeout(() => this.setState({ css: 'Big' }), 150)
                })
        }
        this.scrollToRef(this.PayStatus)
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.stripe && this.props.stripe) {
            const { stripe, clientSecret } = this.props;
            if (!stripe) return
            stripe.retrievePaymentIntent(clientSecret.clientSecret)
                .then(({ paymentIntent }) => {
                    this.setState({ status: paymentIntent.status, id: paymentIntent.id, css: 'Small' })
                    setTimeout(() => this.setState({ css: 'Big' }), 150)
                })
                .catch(err => console.log(err))
        }
    }

    handleGetStatus() {
        if (this.state.status !== 'success') {
            const { stripe, clientSecret } = this.props;
            if (!stripe) return
            stripe.retrievePaymentIntent(clientSecret.clientSecret)
                .then(({ paymentIntent }) => {
                    this.setState({ status: paymentIntent.status, id: paymentIntent.id, css: 'Small' })
                    setTimeout(() => this.setState({ css: 'Big' }), 150)
                })
        }
    }
    
    scrollToRef = ref => {
        ref.current.scrollIntoView({
            behavior: 'smooth'
        })
    }

    getIcon() {
        switch (this.state.status) {
            case 'succeeded':
                return <FcOk />
            case 'requires_payment_method':
                return <FcCancel />
            default:
                return <FcMoneyTransfer />
        }
    }

    getText() {
        switch (this.state.status) {
            case 'succeeded':
                return 'Payment Accepted'
            case 'requires_payment_method':
                return 'Payment Not Accepted, Please Try Again'
            default:
                return 'Payment Processing, Click to Refresh'
        }
    }

    render() {
        return <div><div className='Node-Row' onClick={() => this.handleGetStatus()}>
            <div className='Node-Row' style={{textAlign: 'center'}}>
                <div className={`QR-Status QR-${this.state.css}`}>{this.getIcon()}</div>
                <div className={`QR-Status Pay-${this.state.css}`}>{this.state.status !== 'require_payment_method' && this.state.status !== 'succeeded' && <HiRefresh />}{this.getText()}</div>
            </div>            
        </div><div ref={this.PayStatus} className='Node-Row' style={{marginTop: '100px'}}>&nbsp;</div></div>
    }
}

export default function InjectedPayConfirm(clientSecret) {
    return <ElementsConsumer>
        {({ stripe, elements }) => (
            <PayConfirm stripe={stripe} elements={elements} clientSecret={clientSecret} />
        )}
    </ElementsConsumer>
}