import { Component } from 'react'
import { ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js'
import LoaderButton from './loaderButton'
import '../containers/node.css'

class CheckoutForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isProcessing: false,
            displayMsg: false,
            msg: ""
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        this.setState({ isProcessing: true })
        const { stripe, elements } = this.props;
        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams:  this.props.return_url
        })

        if (result.error) {
            this.setState({ msg: result.error.message, displayMsg: true, isProcessing: false })
        }
    }

    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false })
    }

    getMessage() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Node-Row Node-Flex-Wrap">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    render() {
        return <>
            <form onSubmit={this.handleSubmit}>
                <PaymentElement options={{ layout: "tabs" }} />
                <LoaderButton
                    size="lg"
                    disabled={!this.props.stripe || !this.props.elements}
                    type="submit"
                    isLoading={this.state.isProcessing}
                    text='Pay'
                    loadingText='Processing...'
                    className='Signup-Button'
                    style={{ margin: '10px 0px', bottom: '0' }}
                />
            </form>
            {
                this.state.displayMsg && this.getMessage()
            }
        </>
    }
}

export default function InjectedCheckoutForm(return_url) {
    return <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} return_url={return_url} />
        )}
    </ElementsConsumer>
}