export function getAddress(addr) {
    if (addr) {        
        let address = addr.line1 || ''
        if (addr.line2 && addr.line2.length > 0) address += address.length > 0 ? `, ${addr.line2}` : addr.line2
        if (addr.city && addr.city.length > 0) address += address.length > 0 ? `, ${addr.city}` : addr.city
        if (addr.state && addr.state.length > 0) address += address.length > 0 ? `, ${addr.state}` : addr.state
        if (addr.postalcode && addr.postalcode.length > 4) address += address.length > 0 ? `${addr.state && addr.state.length > 0 ? ' ' : ', '} ${addr.postalcode}` : addr.postalcode
        return address
    } else return null
}

export function temp(k) {
    if(k) return ((k - 273.15) * (9/5) + 32).toFixed(0)
    return ''
}