import React from 'react'
import { WhatsappShareButton, WhatsappIcon } from 'react-share'

const ShareWhat = (props) => {
    //console.log(props)
    return <div>
        <WhatsappShareButton url={props.url} title={props.title} separator=' '>
            <WhatsappIcon size={props.size} round />
        </WhatsappShareButton>
    </div>
}

export default ShareWhat