const config = {
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://e5393no4n6.execute-api.us-east-1.amazonaws.com/prod/"
    },
    s3: {
        REGION: "us-east-1",
        BUCKET: "localtalent-upload"
    },
    cognito: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_ahWNWHc76',
        APP_CLIENT_ID: '7onu3dlgn4beq5ga5hft8rknst',
        IDENTITY_POOL_ID: 'us-east-1:56a6d800-d198-491e-9f93-0f3aac37e458'
    },
    "social": {
        FB: "1054271582234227"
    }
}

export default config
